import React from "react";
import { BiChevronUp } from "react-icons/bi";
import { BiChevronRight } from "react-icons/bi";
import {
  ProfileImage,
  ProfileImageContainer,
  UserCardContainer,
  UserLastMessage,
  UserLastMessageContainer,
  UserName,
  XYCenteredContent,
} from "../../CommonThings/common.styled";
import { AdListContainer, CampaignMain, Slider } from "./adComp.styled";

function AdComp({ showAds, setShowAds, adData, getAdcampaign }) {
  return (
    <CampaignMain>
      <Slider showAds={showAds} onClick={() => setShowAds(!showAds)}>
        <BiChevronUp />
      </Slider>
      <AdListContainer showAds={showAds}>
        {adData.map((user, i) => (
          <UserCardContainer key={i} onClick={(e) => getAdcampaign(e, user)}>
            <ProfileImageContainer>
              <ProfileImage
                src={user.userData.Profilepic || "./images/ad_icon.jpg"}
                onError={(e) => (e.target.src = "./images/ad_icon.jpg")}
                width="60px"
                height="60px"
                alt=""
              />
            </ProfileImageContainer>
            <div className="w-100">
              <UserName>{user.userData?.SenderName}</UserName>
              <XYCenteredContent justify="space-between">
                <UserLastMessageContainer>
                  {user.is_verified && (
                    <UserLastMessage
                      dangerouslySetInnerHTML={{
                        __html: user?.lastMessage?.api_response?.body,
                      }}
                    />
                  )}
                </UserLastMessageContainer>
                <BiChevronRight className="m-2" size={20} />
              </XYCenteredContent>
            </div>
          </UserCardContainer>
        ))}
      </AdListContainer>
    </CampaignMain>
  );
}

export default AdComp;
