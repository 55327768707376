import { Button } from "react-bootstrap";
import styled from "styled-components";

export const WaveMain = styled.div`
  position: relative;
  width: 100%;
`;

export const WavePath = styled.div.attrs({ className: "px-2" })`
  margin-left: 17px;
`;

export const PlayToggle = styled(Button)`
  position: absolute;
  left: 2px;
  top: 1px;
`;
