import {
  databaseConnection,
  User,
  Chat,
  UserMapping,
  Group,
  GroupChat,
} from "./Database";

export const UserSchema = async () => {
  const db = await databaseConnection();
  return db.transaction(User, "readwrite").objectStore(User);
};
export const GroupSchema = async () => {
  const db = await databaseConnection();
  return db.transaction(Group, "readwrite").objectStore(Group);
};

export const ChatSchema = async () => {
  const db = await databaseConnection();
  return await db.transaction(Chat, "readwrite").objectStore(Chat);
};
export const GroupChatSchema = async () => {
  const db = await databaseConnection();
  return await db.transaction(GroupChat, "readwrite").objectStore(GroupChat);
};

export const UserMappingSchema = async () => {
  const db = await databaseConnection();
  return await db
    .transaction(UserMapping, "readwrite")
    .objectStore(UserMapping);
};
