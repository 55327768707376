import React, { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Camera } from "react-camera-pro";
import { SwitchCam } from "./webCam.styled";

const WebcamCapture = ({
  show,
  setShow,
  handleClose,
  setPhotoOrVideo,
  setFileDataFunction,
}) => {
  const videoRef = useRef(null);

  const [image, setImage] = useState({});

  // take picture of user
  const takePicture = async () => {
    let photo64 = videoRef.current.takePhoto();

    let image = await fetch(photo64)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (byteArray) {
        return new File([byteArray], "captured_image_" + Date.now() + ".png", {
          type: "image/png",
        });
      });
    console.log(image);
    setImage(image);
  };

  // clear Picture from screen
  const clearPicture = () => {
    setImage({});
    setFileDataFunction(null, setPhotoOrVideo, "");
  };

  const UploadPicture = () => {
    setFileDataFunction(image, setPhotoOrVideo, "photoOrVideo");

    setImage({});
    handleClose();
  };

  const close = () => {
    handleClose();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={close}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Take a picture</Modal.Title>
          <SwitchCam onClick={() => videoRef.current.switchCamera()} />
        </Modal.Header>
        <Modal.Body>
          <Camera aspectRatio={16 / 12} ref={videoRef} />
        </Modal.Body>
        <Modal.Footer>
          {!image?.name ? (
            <>
              <Button onClick={takePicture} className="btn btn-primary">
                Take Picture
              </Button>
            </>
          ) : (
            <>
              <Button onClick={UploadPicture} className="btn btn-success">
                Upload
              </Button>
              <Button onClick={clearPicture} className="btn btn-danger">
                Clear
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default WebcamCapture;
