import styled from "styled-components";
import { XYCenteredContent } from "../../CommonThings/common.styled";

export const RecordingWave = styled.div`
  width: 100%;
  height: 56px;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
    #ffffff;
  padding: 16px 12px 16px 16px;
  border: 1px solid #d5e0e9;
  border-radius: 8px;
  outline: none;
  padding-right: 100px !important;

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #0d213d;
  }
`;

export const RedDot = styled.span`
  background-color: red;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: block;
`;

export const RecordStatus = styled(XYCenteredContent).attrs({
  className: "gap-2",
})`
  position: absolute;
  top: 20px;
  right: 10px;
`;
