import { openDB } from "idb";
import { DATABASE } from "../Auth/config";
export const User = "User";
export const Group = "Group";
export const Chat = "Chat";
export const GroupChat = "GroupChat";
export const UserMapping = "UserMapping";

const db = async () => {
  try {
    const connection = await openDB(DATABASE, 1, {
      upgrade(db) {
        db.createObjectStore(User, {
          keyPath: "id",
          autoIncrement: true,
          unique: true,
        });
        db.createObjectStore(Group, {
          keyPath: "id",
          autoIncrement: true,
          unique: true,
        });
        db.createObjectStore(Chat, {
          keyPath: "id",
          autoIncrement: true,
          unique: true,
        });
        db.createObjectStore(GroupChat, {
          keyPath: "id",
          autoIncrement: true,
          unique: true,
        });
        db.createObjectStore(UserMapping, {
          keyPath: "id",
          autoIncrement: true,
          unique: true,
        });
      },
    });
    if (connection) {
      return { status: true, connection };
    } else {
      return { status: false, connection: "Error while connect to indexDB." };
    }
  } catch (error) {
    console.log(error);
    return { status: false, connection: "Error while connect to indexDB." };
  }
};

export const databaseConnection = async () => {
  const { status, connection } = await db();
  // console.log(status);
  if (status) {
    return connection;
  } else {
    console.log("Error while connect to database.");
  }
};

export const databaseDelete = () => {
  indexedDB.deleteDatabase(DATABASE);
  console.log("database deleted.");
};
