import axios from "axios";
import {
  CHAT_MAIL_APP_POST,
  GET,
  GROUP_API_POST,
  POST,
  STATIC_PAGE_GET,
} from "./axiosMethod";
import { COMMON_ERROR } from "./config";

const errorResponse = (error) => {
  if (error.response) {
    const { status, data } = error.response;
    if (status === 403) {
      setTimeout(() => {
        window.location.href = "/";
        localStorage.clear();
      }, 5000);
    }
    return data;
  } else {
    return { status: 0, message: COMMON_ERROR };
  }
};

// export const codeDecrypt = async (requestData) => {
//       try {
//             const { sessionId } = requestData
//             const codeDecryptResponse = await GET(`/homePage?sessionId=${sessionId}`)
//             // console.log(codeDecryptResponse);
//             const { data } = codeDecryptResponse
//             return data
//       } catch (error) {
//             console.log(error);
//             return errorResponse(error)
//       }
// }

export const verifyOTP = async (requestData) => {
  try {
    const { phoneNumber, otpNum } = requestData;
    // const verifyOTPResponse = await POST(`/verify_otp`, { phoneNumber, otpNum })
    const verifyOTPResponse = await POST(`/verify_otp`, { otpNum });
    const { data } = verifyOTPResponse;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const sendMessageToAppUser = async (requestData) => {
  try {
    // const { webID, contactID, recipientUserID, subject, typeID, Body, } = requestData
    const sendMessageToAppUserResponse = await POST(
      `/sendMessageToAppUser`,
      requestData
    );
    const { data } = sendMessageToAppUserResponse;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const sendMessageToGroupAPI = async (requestData) => {
  try {
    // const { webID, contactID, recipientUserID, subject, typeID, Body, } = requestData
    const response = await GROUP_API_POST(`/sending_message`, requestData);
    const { data } = response;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const getGroupInfo = async (requestData) => {
  try {
    const response = await GROUP_API_POST(`/groupInfo`, requestData);
    const { data } = response;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const getAdCampainDetails = async (requestData) => {
  try {
    const response = await CHAT_MAIL_APP_POST(`/getCampaign`, requestData);
    const { data } = response;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const saveDeviceToken = async (requestData) => {
  try {
    const { contactId, deviceToken } = requestData;
    const saveDeviceTokenResponse = await POST(`/saveDeviceToken`, {
      contactId,
      deviceToken,
    });
    const { data } = saveDeviceTokenResponse;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const getPendingInvite = async (requestData) => {
  try {
    const { contactId } = requestData;
    const getPendingInviteResponse = await POST(`/getPendingInvite`, {
      contactId,
    });
    const { data } = getPendingInviteResponse;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const leaveGroupChatAPI = async (contact_id, groupId) => {
  try {
    const request_data = {
      sessionId: contact_id,
      groupId,
    };
    const leaveGroupResponse = await GROUP_API_POST(
      `/leaveGroup`,
      request_data
    );
    const { data } = leaveGroupResponse;
    return data;
  } catch (error) {
    console.error("leaveGroupChatAPI_ERROR", error);
    return errorResponse(error);
  }
};

export const logoutFromServer = async (requestData) => {
  try {
    const { contactId, webID } = requestData;
    const logoutResponse = await POST(
      `/logout`,
      contactId ? { contactId } : { webId: webID }
    );
    console.warn("logoutFromServer", logoutResponse);
    const { data } = logoutResponse;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const getStaticpage = async (pageName) => {
  try {
    const responseText = await STATIC_PAGE_GET(`/content?pageName=${pageName}`);
    const { pageDetail } = responseText.data;
    return pageDetail;
  } catch (error) {
    return errorResponse(error);
  }
};

export const getUpdatedImage = async (requestData) => {
  try {
    return await POST(`/getUserProfile`, requestData);
  } catch (error) {
    return errorResponse(error);
  }
};
