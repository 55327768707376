import React, { useState, useEffect } from "react";
import {
  createUserMapping,
  getAllUserMapping,
  logout,
} from "../../../IndexedDB/queries";
import Chat from "../../chat/Chat";
import Loading from "../../Loading/Loading";
import IOSUserInfo from "../iOSUserInfo/IOSUserInfo";
import Start from "../Start/Start";

const Welcome = () => {
  const [loginStatus, setLoginStatus] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async (userDetails = null) => {
    if (userDetails) {
      const data = {
        contact_id: userDetails.contactID,
        sender_ids: userDetails.SenderID,
        is_login: true,
      };
      const createMapping = await createUserMapping(data);
    }

    const getAllUserResponse = await getAllUserMapping();

    if (getAllUserResponse.status === 1) {
      const logged_in_user = await getAllUserResponse.data.find(
        (user) => user.is_login === true
      );
      if (logged_in_user) {
        setLoginStatus(true);
        setLoading(true);
        timeoutFunction();
      } else {
        const logoutResponse = await logout();
        if (logoutResponse?.status === 1) {
          timeoutFunction();
        } else {
          timeoutFunction();
        }
      }
    } else {
      timeoutFunction();
    }
  };

  const timeoutFunction = () => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const logoutFromChat = () => {
    setLoginStatus(false);
    setLoading(true);
    timeoutFunction();
  };

  return (
    <>
      <IOSUserInfo />
      {loading ? (
        <Loading />
      ) : loginStatus ? (
        <Chat logoutFromChat={logoutFromChat} />
      ) : (
        <Start getUserData={getUserData} />
      )}
    </>
  );
};

export default Welcome;
