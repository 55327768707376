import React, { useRef, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import WaveSurfer from "wavesurfer.js";
import { Pause, Play } from "../../CommonThings/common.styled";
import { HiPlay } from "react-icons/hi";
import { PlayToggle, WaveMain, WavePath } from "./waveSurfer.styled";

const Wavesurfer = ({ url, setRecordDuration }) => {
  const waveformRef = useRef();
  const waveSurfer = useRef();

  const [play, setPlay] = useState(false);

  useEffect(() => {
    // if (waveformRef.current) {
    // const url = 'https://www.mfiles.co.uk/mp3-downloads/gs-cd-track2.mp3';
    waveSurfer.current = WaveSurfer.create({
      container: waveformRef.current,
      // waveColor: 'violet',
      // progressColor: 'purple',
      // scrollParent: true
      waveColor: "#A4BCD0",
      progressColor: "#505AFF",
      cursorColor: "#505AFF",
      responsive: true,
      height: 30,
      width: 30,
      drag: false,
      // // If true, normalize by the maximum peak instead of 1.0.
      normalize: true,
      // // Use the PeakCache to improve rendering speed of large waveforms.
      // partialRender: true,
      barGap: "3",
      barWidth: 3,
      barRadius: 3,
      cursorWidth: 1,
      Muting: true,
      returnOnPause: true,
      moveOnSeek: true,
      draw: true,
    });
    waveSurfer.current.load(url);

    waveformRef.current.children[0].style.overflow = "hidden";

    return () => waveSurfer.current.destroy();
    // console.log(waveformRef.current);
    // }

    // waveSurferCreate.on('ready', function () {
    //     waveSurferCreate.play();
    // });
  }, []);

  const playMusic = (e) => {
    e.preventDefault();
    if (!play) {
      console.log(waveSurfer.current);
      // waveSurfer.current.play();
      waveSurfer.current.play();
      setPlay(!play);
    } else {
      console.log(waveSurfer.current);
      // waveSurfer.current.play();
      waveSurfer.current.pause();
      setPlay(!play);
    }

    waveSurfer.current.on("audioprocess", function (time) {
      console.log(time);
      if (waveSurfer.current.isPlaying()) {
        var totalTime = waveSurfer.current.getDuration(),
          currentTime = waveSurfer.current.getCurrentTime(),
          remainingTime = totalTime - currentTime;

        const data = {
          currentTime: currentTime.toFixed(1),
          remainingTime: remainingTime.toFixed(1),
          totalTime: totalTime.toFixed(1),
        };

        setRecordDuration(data);

        if (remainingTime.toFixed(1) === "0.0") {
          setPlay(false);
        }
      }
    });
  };

  return (
    <WaveMain>
      <PlayToggle onClick={playMusic}>
        {play ? <Pause width="22" /> : <Play width="18" />}
      </PlayToggle>
      <WavePath ref={waveformRef}></WavePath>
    </WaveMain>
  );
};

export default Wavesurfer;
