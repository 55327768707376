import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { IoIosClose } from "react-icons/io";
import { XYCenteredContent } from "../../CommonThings/common.styled";
import { submitOTP } from "../../CommonThings/CommonThings";
import {
  CloseBtn,
  HiddenInput,
  ModalButtons,
  OTPInput,
  OTPMain,
  OTPModal,
  Reset,
  TextOTP,
} from "./otp_component.styled";
import CMLoader from "../Loader/CMLoader";

const OTPComponent = ({
  show,
  setShow,
  getUserData,
  updateData = null,
  setSidebar,
  sidebarRef,
}) => {
  const [otpNumber1, setOtpNumber1] = useState("");
  const [loading, setLoading] = useState(false);

  const input1 = useRef(null);
  // const sidebarRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (input1.current) {
        input1?.current?.focus();
      }
    }, 100);
  }, [show]);

  // if otp is filled then it is called.
  useEffect(() => {
    if (otpNumber1.length === 4) {
      OTP_Verification();
    }
  }, [otpNumber1]);

  const OTP_Verification = async () => {
    setLoading(true);
    await submitOTP(
      updateData,
      sidebarRef,
      otpNumber1,
      getUserData,
      setSidebar,
      closeModal
    );
    setLoading(false);
  };

  // close model and clear OTP
  const closeModal = () => {
    clearOTP();
    setShow(false);
  };
  // clear OTP
  const clearOTP = () => {
    setOtpNumber1("");
    input1?.current?.focus();
  };

  return (
    <OTPModal
      show={show}
      onHide={closeModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        {loading ? (
          <CMLoader loading_text="Verifying" />
        ) : (
          <OTPMain className="p-3" onSubmit={OTP_Verification}>
            <XYCenteredContent
              justify="center"
              className="mb-3 mb-sm-4 mt-lg-0 mt-3"
            >
              <TextOTP>Enter your OTP</TextOTP>
              <ModalButtons>
                <Reset width="18px" alt="arrow-clockwise" onClick={clearOTP} />
                <CloseBtn className="close-btn" onClick={closeModal}>
                  <IoIosClose />
                </CloseBtn>
              </ModalButtons>
            </XYCenteredContent>
            <XYCenteredContent justify="center" className="gap-3">
              <HiddenInput
                className="opacity-0"
                ref={input1}
                type="text"
                inputMode="numeric"
                autoComplete="off"
                onBlur={() => input1?.current?.focus()}
                onChange={(e) => {
                  setOtpNumber1(e.target.value.replace(/[^0-9]/g, ""));
                }}
                value={otpNumber1}
                maxLength="4"
              />
              <OTPInput
                type="text"
                disabled={true}
                inputMode="numeric"
                autoComplete="off"
                value={otpNumber1.charAt(0)}
                maxLength="1"
              />
              <OTPInput
                type="text"
                disabled={true}
                inputMode="numeric"
                autoComplete="off"
                value={otpNumber1.charAt(1)}
                maxLength="1"
              />
              <OTPInput
                type="text"
                disabled={true}
                inputMode="numeric"
                autoComplete="off"
                value={otpNumber1.charAt(2)}
                maxLength="1"
              />
              <OTPInput
                type="text"
                disabled={true}
                inputMode="numeric"
                autoComplete="off"
                value={otpNumber1.charAt(3)}
                maxLength="1"
              />
            </XYCenteredContent>
          </OTPMain>
        )}
      </Modal.Body>
    </OTPModal>
  );
};

export default OTPComponent;
