import moment from "moment";
import { toast } from "react-toastify";
import { getGroupInfo, getUpdatedImage, verifyOTP } from "../../Auth/api";
import {
  createChat,
  createGroup,
  createGroupChat,
  createUser,
  createUserMapping,
  getAllChats,
  getAllGroupChats,
  getAllGroups,
  getAllUserMapping,
  getAllUsers,
  updateGroup,
  updateGroupChat,
  updateUser,
} from "../../IndexedDB/queries";
import { DATABASE } from "../../Auth/config";

export const getTimeFormat = (sentTime, isMessage = true) => {
  if (sentTime) {
    var REFERENCE = moment();
    var YESTERDAY = REFERENCE.clone().subtract(1, "days").startOf("day");
    var UTCtoDate = moment.utc(sentTime).toDate();
    var local = moment(UTCtoDate).local().format("YYYY-MM-DD HH:mm");
    let isYesterday = moment(local).isSame(YESTERDAY, "d");
    let date = new Date(local);
    var strTime = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    let daysAgo = moment().diff(moment(sentTime), "days");
    if (isMessage) {
      return strTime;
    } else {
      if (isYesterday) {
        return "Yesterday";
      }

      if (daysAgo > 1) {
        return `${daysAgo} days ago`;
      }
      return strTime;
    }
  }
};

const getCurrentLoggedInUser = async () => {
  const getAllUserResponse = await getAllUserMapping();
  if (getAllUserResponse.status === 1) {
    const currentLoginUser = getAllUserResponse.data.find(
      (user) => user.is_login === true
    );
    return currentLoginUser;
  } else {
    return null;
  }
};

export const getVerifiedSender = async (senderList, status) => {
  const currentLoginUser = await getCurrentLoggedInUser();
  if (currentLoginUser?.id) {
    const list = senderList.filter(
      (user) =>
        user.userData &&
        user.is_verified === status &&
        currentLoginUser.contact_id === user.userData.contactID &&
        currentLoginUser.sender_ids.includes(user.userData.SenderID)
    );
    return list;
  } else {
    return [];
  }
};

export const getCommonInvitation = async (sender, sidebarRef) => {
  if (sender.groupID || sender.groupId) {
    if (sender.groupId) {
      await sidebarRef.current.callPendingInviteAPI(Number(sender.contactId));
      return;
    }
    const groupRequestData = {
      groupData: sender,
      is_verified: false,
    };

    const createGroupResponse = await createGroup(groupRequestData);

    if (createGroupResponse.status === 1) {
      return groupRequestData;
    }
  } else if (sender.sendingUserID) {
    const userRequestData = {
      userData: {
        Profilepic: sender.profilePic,
        SenderNumber: sender.senderNumber,
        SenderID: Number(sender.recipientUserID),
        contactID: Number(sender.sendingUserID),
        SenderName: sender.title,
        webID: Number(sender.webID),
        type: Number(sender.type),
        invite_time: Date(),
      },
      is_verified: false,
    };
    const currentLoginUser = await getCurrentLoggedInUser();
    if (
      !currentLoginUser?.sender_ids?.includes(userRequestData.userData.SenderID)
    ) {
      currentLoginUser.sender_ids = userRequestData.userData.SenderID;
      const createMapping = await createUserMapping(currentLoginUser);
      // sidebarRef.current.fetchAllChats(null)
      // return null
    } else {
      // const update_user = await updateUser({
      //   ...sender,
      //   existing_user: sender.existing_user,
      // });
      console.log("currentLoginUserELSE");
    }
    const createUserResponse = await createUser(userRequestData);
    if (createUserResponse.status === 1) {
      // sidebar component function to fetchAllChats and update sidebar.
      console.log("createUserResponse: Success");
      // await sidebarRef.current.fetchAllChats();
      return userRequestData;
    }
  }

  // await sidebarRef.current.fetchAllChats();
};

const getCommonMessage = async (
  message,
  sidebarRef,
  userChat,
  senderID,
  contactID
) => {
  message.typeID = Number(message.typeID);
  const requestData = {
    // role: userType ? "user" : "sender",
    role: "sender",
    status: true,
    currentTime: Date.now(),
    api_response: message,
    sent_time: !message && Date(),
  };

  // replied messages
  if (message?.isReply == "true" && message?.repliedMessageID) {
    const allChats = await getAllChats();
    if (allChats.status === 1) {
      const repliedData = allChats.data.find(
        (data) =>
          data?.api_response?.uniqueMessageID === message?.repliedMessageID
      );
      if (repliedData) {
        requestData.reply = repliedData;
      }
    }
  }
  const sendMessage = await createChat(requestData);
  const id = sendMessage.data;
  //store message ID(IndexedDB)
  requestData.id = id;

  await sidebarRef.current.addLastMessageInUserList(requestData, senderID);

  if (sendMessage.status === 1) {
    if (message.groupID) {
      const groupMessageData = {
        group_id: Number(message.groupID),
        chat_ids: [id],
      };

      const groupChatData = await getAllGroupChats();

      //check if group chat exists
      const found_data = groupChatData.data.find(
        (gc) => gc.group_id === Number(message.groupID)
      );

      if (found_data) {
        //update chat_ids array
        groupMessageData.id = found_data.id;
        found_data.chat_ids.push(id);
        groupMessageData.chat_ids = found_data.chat_ids;

        const updateGroupChatData = await updateGroupChat(groupMessageData);
      } else {
        //create groupChat
        const saveToGroupChat = await createGroupChat(groupMessageData);
        if (saveToGroupChat.status === 1) {
          console.warn("saveToGroupChatOnReceive", saveToGroupChat);
        } else {
          console.error("saveToGroupChatOnReceiveELSE", saveToGroupChat);
        }
      }

      //Check if group exists, if not then create one
      const all_groups = await getAllGroups();
      if (all_groups.status == 1) {
        const groupExistance = all_groups.data.find(
          (group) => group.groupData.groupID == message.groupID
        );
        if (!groupExistance) {
          const groupDetails = await getGroupInfo({
            sessionId: Number(message.contactId),
            groupId: Number(message.groupID),
          });
          if (groupDetails.status == 200) {
            const { ID, description, members, name, owner, profilePic } =
              groupDetails.data;

            let all_members = [owner, ...members];

            var rgb = [];
            all_members.forEach((m) => {
              for (var i = 0; i < 3; i++) {
                rgb.push(Math.floor(Math.random() * 255));
              }
              m.color = `rgb(${rgb.join(",")})`;
              rgb = [];
            });

            const groupDataFormat = {
              groupData: {
                contactID: message.contactId,
                groupDescription: description,
                groupID: ID,
                groupName: name,
                invitedAt: false,
                members: all_members,
                profilePic,
              },
              is_verified: true,
              lastMessage: {
                api_response: message,
                currentTime: Date.now(),
                id,
                role: "sender",
                sent_time: Date(),
                status: true,
              },
            };

            const createGroupResponse = await createGroup(groupDataFormat);

            console.log("createGroupResponse", createGroupResponse);
          }
        }
      }
    }
    console.log("added message -----");
    return requestData;
  } else {
    console.log("check error.");
  }
};

export const addMassagesAndInvitationInIndexedDB = async (
  payload,
  sidebarRef,
  userChat,
  senderID,
  contactID
) => {
  // invite user request
  if (Number(payload.type) == 2) {
    await getCommonInvitation(payload, sidebarRef);
    return null;
  }
  // chat message from sender
  if (
    Number(payload.type) == 1 ||
    payload.type == "groupchat" ||
    payload.type == "chat"
  ) {
    const response = await getCommonMessage(
      payload,
      sidebarRef,
      userChat,
      payload.groupID || senderID,
      contactID
    );
    return response;
  }
};

export const isIOS = (setiOSDevice = null) => {
  const browserInfo = navigator.userAgent.toLowerCase();

  if (browserInfo.match("iphone") || browserInfo.match("ipad")) {
    console.log("YES1", navigator.platform);
    if (setiOSDevice) {
      return setiOSDevice("YES");
    }
    return "YES";
  }
  if (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform)
  ) {
    if (setiOSDevice) {
      return setiOSDevice("YES");
    }
    return "YES";
  }
  if (setiOSDevice) {
    return setiOSDevice("NO");
  }
  return "NO";
};

export const clearSiteDataFn = () => {
  sessionStorage.clear();
  localStorage.clear();
  caches.keys().then((keys) => {
    keys.forEach((key) => caches.delete(key));
  });
  if (indexedDB?.databases) {
    indexedDB?.databases()?.then((dbs) => {
      dbs.forEach((db) => indexedDB.deleteDatabase(db.name));
    });
  } else {
    indexedDB.deleteDatabase(DATABASE);
  }

  document.cookie = document.cookie.split(";").reduce((newCookie1, keyVal) => {
    var pair = keyVal.trim().split("=");
    if (pair[0]) {
      if (pair[0] !== "path" && pair[0] !== "expires") {
        newCookie1 += pair[0] + "=;";
      }
    }
    return newCookie1;
  }, "expires=Thu, 01 Jan 1970 00:00:00 UTC; path:/;");
  navigator?.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
};

export const submitOTP = async (
  updateData,
  sidebarRef,
  otpNumber1,
  getUserData,
  setSidebar,
  closeModal
) => {
  const not_this_chat = "This access code does not belong to this chat !!";
  if (otpNumber1.length === 4) {
    const requestData = {
      otpNum: otpNumber1.trim(),
    };
    // call verifyOTP API
    const response = await verifyOTP(requestData);
    if (response?.status === 200) {
      const { messages, ...chat_details } = response.data;

      //save messages
      const messagesArray = messages?.map(async (message) => {
        let { sentBy, ...messageResponse } = message;
        if (messageResponse?.uniqueMessageID) {
          let save_message = {
            role: "sender",
            status: true,
            currentTime: message.sentTime,
            api_response: { ...messageResponse },
          };

          // create chat in indexedDB
          const savedMessage = await createChat(save_message);
          save_message.id = savedMessage?.data;
          return save_message;
        } else {
          return {};
        }
      });

      const all_saved_messages = await Promise.all(messagesArray).then(
        (values) => {
          return values;
        }
      );

      const lastMessage = all_saved_messages[all_saved_messages.length - 1];

      if (chat_details.webID) {
        const user_detail = {
          userData: chat_details,
          is_verified: true,
          lastMessage,
        };
        let userResponse = null;

        if (updateData?.id) {
          // update user in indexedDB
          if (
            updateData?.userData?.SenderID == user_detail?.userData?.SenderID
          ) {
            user_detail.id = updateData?.id;
            userResponse = await updateUser(user_detail);
            setTimeout(async () => {
              await sidebarRef.current.fetchAllChats(
                user_detail?.userData?.webID,
                null
              );
            }, 200);
            await sidebarRef.current.onUserListClick(user_detail);
          } else {
            return toast.error(not_this_chat);
          }
        } else {
          // create user in indexedDB
          userResponse = await createUser(user_detail);
        }
        if (userResponse.status === 1) {
          closeModal();
        }
      } else {
        const {
          name,
          contactID,
          description,
          ID,
          members,
          profilePic,
          invitedAt,
        } = chat_details;

        var rgb = [];
        members.forEach((member) => {
          for (var i = 0; i < 3; i++) {
            rgb.push(Math.floor(Math.random() * 255));
          }
          member.color = `rgb(${rgb.join(",")})`;
          rgb = [];
        });

        const group_data = {
          groupData: {
            contactID: Number(contactID),
            groupDescription: description,
            groupID: ID,
            groupName: name,
            invitedAt,
            members,
            profilePic,
          },
          is_verified: true,
          lastMessage,
        };
        let groupResponse = null;

        const findAllGroups = await getAllGroups();
        const existing_group = findAllGroups.data.find(
          (g) => g?.groupData?.groupID == ID
        );

        if (
          existing_group &&
          existing_group?.groupData?.contactID != response.data.contactID
        ) {
          closeModal();
          return toast.error("Incorrect OTP");
        }

        if (updateData?.id) {
          if (
            updateData?.groupData?.groupID == group_data?.groupData?.groupID
          ) {
            group_data.id = updateData?.id;
            groupResponse = await updateGroup(group_data);

            await sidebarRef.current.onUserListClick(group_data);
          } else {
            return toast.error(not_this_chat);
          }
        } else {
          if (existing_group) {
            existing_group.is_verified = true;
            groupResponse = await updateGroup(existing_group);
          } else {
            groupResponse = await createGroup(group_data);
          }
        }
        if (groupResponse.status === 1) {
          closeModal();
        }
      }

      // update user/group details
      updateData == null && getUserData(chat_details);
      setSidebar && setSidebar(false);
    } else {
      response?.status === 500 || !response
        ? toast.error("Internal server error.")
        : toast.error("Incorrect OTP.");
    }
  }
};

export const getUpdatedProfileImage = async (
  user,
  setProfileImage,
  preventAPICall = false
) => {
  if (user?.userData) {
    const userProfileImage =
      user?.userData?.profilePic || user?.userData?.Profilepic;
    if (userProfileImage) {
      if (preventAPICall) return setProfileImage(userProfileImage);
    }
    const userId = user?.userData?.SenderID;
    const APIresponse = await getUpdatedImage({ userId, type: "systemuser" }); //App users
    if (Number(APIresponse?.status) === 200) {
      const userIDB = await findUserOrGroupById("user", userId);
      if (Number(userIDB?.userData?.SenderID) === Number(userId)) {
        userIDB.userData.Profilepic = APIresponse?.data?.data?.Profilepic;
        const updateIDBUser = await updateUser(userIDB);
        console.log("userIDB", updateIDBUser, Date().toString());
      }
      return setProfileImage(
        APIresponse?.data?.data?.Profilepic ||
          user?.userData?.profilePic ||
          user?.userData?.Profilepic
      );
    }
  } else if (user?.type) {
    const type = user.type === "appUser" ? "systemuser" : user.type;
    const APIresponse = await getUpdatedImage({
      userId: user.id || user.ID,
      type,
    }); //group members
    if (preventAPICall) {
      return setProfileImage(user?.picturePath || user?.profilePic);
    }
    if (Number(APIresponse?.status) === 200) {
      return setProfileImage(
        APIresponse?.data?.data?.Profilepic ||
          user?.picturePath ||
          user?.profilePic
      );
    }
  } else if (user?.groupData) {
    const groupProfileImage =
      user?.groupData?.profilePic ||
      user?.groupData?.Profilepic ||
      (user?.groupData && "./images/g_icon.ico");
    if (groupProfileImage) {
      setProfileImage(groupProfileImage);
    }
  }
};

//sort list Fn
export const sortChatList = async () => {
  let chatList = [];
  const get_allUsers = await getAllUsers();
  if (get_allUsers.status === 1) {
    const all_users = get_allUsers.data;
    chatList.push(...all_users);
  }
  const get_allGroups = await getAllGroups();
  if (get_allGroups.status === 1) {
    const all_groups = get_allGroups.data;
    chatList.push(...all_groups);
  }

  chatList = chatList.filter((chat) => !chat.is_ad); // remove ads

  const verifiedChats = chatList.filter((chat) => chat.is_verified);
  const invites = chatList.filter((chat) => !chat.is_verified);

  const sorted_chats = verifiedChats.sort((a, b) => {
    let a_time =
      a?.lastMessage?.api_response?.sentTime ||
      a?.lastMessage?.currentTime ||
      a?.userData?.invite_time ||
      a?.groupData?.invitedAt;
    let b_time =
      b?.lastMessage?.api_response?.sentTime ||
      b?.lastMessage?.currentTime ||
      b?.userData?.invite_time ||
      b?.groupData?.invitedAt;

    let a_moment = moment.utc(a_time);
    let b_moment = moment.utc(b_time);

    if (a_time && b_time) {
      return a_moment < b_moment ? 1 : -1;
    } else {
      return 1;
    }
  });

  const sorted_invites = invites.sort((a, b) => {
    let a_time = moment.utc(
      a?.userData?.invite_time || a?.groupData?.invitedAt
    );
    let b_time = moment.utc(
      b?.userData?.invite_time || b?.groupData?.invitedAt
    );

    return a_time < b_time ? 1 : -1;
  });

  return [...sorted_chats, ...sorted_invites];
};

export const findUserOrGroupById = async (chatType, chatId) => {
  let all_chats = await sortChatList();
  if (chatType == "user") {
    const find_user = all_chats.find(
      (user) => user?.userData?.SenderID == chatId
    );
    return find_user;
  } else if (chatType == "group") {
    const find_group = all_chats.find(
      (user) => user?.groupData?.groupID == chatId
    );
    return find_group;
  }
};

export const highlightedLog = (highlightStr, data, textColor) => {
  console.log(
    `%c${highlightStr}`,
    `color: ${textColor || "cadetblue"}; 
     font-family: sans-serif; 
     background-color:black;
     padding:4px;
     font-weight:bold;
     border:1px solid yellow;`,
    "\n",
    data
  );
};
