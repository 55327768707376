import React, { useEffect, useState } from "react";
import MicRecorder from "mic-recorder-to-mp3";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import {
  AddFiles,
  Delete,
  Pause,
  Play,
  SendInputContainer,
  XYCenteredContent,
} from "../../CommonThings/common.styled";
import { RecordingWave, RecordStatus, RedDot } from "./audioElement.styled";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const AudioElement = ({ getRecordDetailFormMicRecord, stopMicRecording }) => {
  /*
    Links :
          https://medium.com/front-end-weekly/recording-audio-in-mp3-using-reactjs-under-5-minutes-5e960defaf10
          https://github.com/Matheswaaran/react-mp3-audio-recording
          https://www.npmjs.com/package/mic-recorder-to-mp3
  */

  const [state, setState] = useState({
    isRecording: false,
    blobURL: "",
    isBlocked: false,
  });

  const [stream, setStream] = useState();

  useEffect(() => {
    getAudioPermission();
  }, []);

  const getAudioPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setState((data) => ({ data, isBlocked: false }));
      start();
      setStream(stream);
    } catch (error) {
      console.error(error);
      setState((data) => ({ data, isBlocked: true }));
      toast.error(error.message);
      stopMicRecording();
    }
  };

  const start = () => {
    if (state.isBlocked) {
      console.log("Permission Denied");
    } else {
      Mp3Recorder.start()
        .then(() => {
          setState((data) => ({ data, isRecording: true }));
        })
        .catch((e) => console.error(e));
    }
  };

  const stop = async () => {
    try {
      const [buffer, blob] = await Mp3Recorder.stop().getMp3();

      const blobURL = URL.createObjectURL(blob);

      console.log(buffer, blob, blobURL);
      const file = new File(buffer, "captured_voice_" + Date.now() + ".mp3", {
        type: "audio/mp3",
      });
      setState((data) => ({ data, blobURL, isRecording: false }));
      console.log(file);
      const data = {
        // recordedBlob,
        audioFile: file,
        blobURL: blobURL,
        // recordTime,
      };

      console.log(data);
      getRecordDetailFormMicRecord(data);

      // stop recorder in tab red blink
      stream.getTracks().forEach(function (track) {
        track.stop();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteRecorded = () => {
    /* 
      Link:            
            https://www.google.com/search?q=stopStreamsOnStop+in+navigator+mediaDevices+getUserMedia&sxsrf=ALiCzsZYELgpmYmUBo1niSTq6JlOtMv_hQ%3A1656655545460&ei=uY6-Yo_GG7_Qz7sPxZKaiAk&ved=0ahUKEwjPhrXqgtf4AhU_6HMBHUWJBpEQ4dUDCA4&uact=5&oq=stopStreamsOnStop+in+navigator+mediaDevices+getUserMedia&gs_lcp=Cgdnd3Mtd2l6EAM6BwgAEEcQsANKBAhBGABKBAhGGABQYlindmDIeGgCcAF4AIABwAGIAZELkgEEMC4xMJgBAKABAaABAsgBCMABAQ&sclient=gws-wiz
            https://stackoverflow.com/a/12436772
            https://developer.chrome.com/blog/mediastream-deprecations/#stop-ended-and-active
    */

    // stop recorder in tab red blink
    stream.getTracks().forEach(function (track) {
      track.stop();
      console.log("stop recorder ----------- ", track);
      stopMicRecording();
    });
  };

  return (
    <SendInputContainer justify="space-between">
      <AddFiles>
        <Button>
          <Delete width="20" height="24" />
        </Button>
      </AddFiles>
      <div className="w-100 position-relative">
        <RecordingWave></RecordingWave>
        <RecordStatus>{state.isRecording && <RedDot />}</RecordStatus>
      </div>
      <XYCenteredContent justify="center">
        <Button>
          {state.isRecording ? (
            <Pause width="22" height="22" onClick={stop} />
          ) : (
            <Play width="18" height="18" onClick={start} />
          )}
        </Button>
      </XYCenteredContent>
    </SendInputContainer>
  );
};

export default AudioElement;
