import styled from "styled-components";

export const ChatListContainer = styled.div`
  padding: 1.5rem !important;
`;

export const SearchByLabel = styled.h6`
  padding: 0.25rem !important;
  text-align: center !important;
  background-color: #f8f9fa !important;
`;

export const MultipleDeletion = styled.div.attrs({ className: "d-flex" })`
  position: relative;
  top: 8px;
  font-size: 2rem;
  justify-content: end;
  svg {
    width: 40px;
    height: 40px;
    padding: 8px;
    background-color: #8baaffa1;
    color: #ff0000d3;
    border-radius: 50%;
    transition: all 0.1s;
  }
  svg:hover {
    width: 44px;
    height: 44px;
    cursor: pointer;
    padding: 8px;
    background-color: rgb(190, 207, 255);
    color: #ff0000;
    border-radius: 50%;
  }
`;
