import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { BsInfoCircle } from "react-icons/bs";
import { getStaticpage } from "../../../Auth/api";
import { InstructionTitle } from "./permissionInstructions.styled";

function PermissionInstructions() {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [pageContent, setPageContent] = useState("");

  const getPageDetails = async () => {
    const pageDetail = await getStaticpage("enable push notification");
    setPageContent(pageDetail);
  };

  useEffect(() => {
    getPageDetails();
  }, []);

  return (
    <>
      <button className="badge mt-2 mb-1" onClick={handleShow}>
        <h4 className="rounded p-2 d-inline bg-danger">
          <BsInfoCircle className="m-1" />
        </h4>
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="instruction-modal"
        size="xl"
        centered
      >
        <Modal.Header>
          <InstructionTitle
            id="instruction-modal"
            className="m-auto"
            style={{ textTransform: "capitalize" }}
          >
            <div dangerouslySetInnerHTML={{ __html: pageContent?.pageName }} />
          </InstructionTitle>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: pageContent?.pageContent }} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PermissionInstructions;
