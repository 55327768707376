import React, { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { toast } from "react-toastify";
import { deleteChatFromChatList } from "../../../IndexedDB/queries";
import { FW700, XYCenteredContent } from "../../CommonThings/common.styled";
import {
  ChatListContainer,
  MultipleDeletion,
  SearchByLabel,
} from "./chatList.styled";
import UserCard from "./UserCard/UserCard";
import { findUserOrGroupById } from "../../CommonThings/CommonThings";

function ChatList({
  userData,
  onUserListClick,
  fetchAllChats,
  clearConversation,
  setChatDetail,
  callLogoutAPI,
  select,
  setSelect,
  setShowOTPModel,
  chatDetail,
  search,
  searchResult,
}) {
  const [selectedChats, setSelectedChats] = useState([]);
  const [searchByMessge, setSearchByMessge] = useState([]);

  const getSenderListForFoundMessages = async () => {
    if (searchResult?.messages?.length) {
      let newArr = await searchResult?.messages?.map(async (message) => {
        const chat_id = Number(
          message?.api_response?.groupID ||
            message?.api_response?.recipientUserID
        );
        let sender;
        if (message?.api_response?.groupID) {
          sender = await findUserOrGroupById("group", chat_id).then((r) => r);
        } else if (message?.api_response?.recipientUserID) {
          sender = await findUserOrGroupById("user", chat_id).then((r) => r);
        }
        sender.foundMessage = message;
        return sender;
      });
      newArr = await Promise.all(newArr).then((r) => r);
      setSearchByMessge(newArr);
    }
  };

  useEffect(() => {
    getSenderListForFoundMessages();
  }, [searchResult]);

  const deleteSelectedChats = async () => {
    if (selectedChats.length) {
      selectedChats.forEach(async (chat_id) => {
        await userData.forEach(async (user) => {
          if (
            (user?.userData?.SenderID || user?.groupData?.groupID) == chat_id
          ) {
            await deleteChatFromChatList(user);
          }
        });
      });
      setSelectedChats([]);
      setSelect(false);
      toast.success("Chats were deleted successfully.");
    } else {
      toast.error("Please select chats to be deleted.");
    }
    setTimeout(async (e) => {
      const current_users = await fetchAllChats(
        chatDetail?.userData?.webID ?? null,
        chatDetail?.groupData?.groupID ?? null
      );
      !current_users && callLogoutAPI(e, "complete");
    }, 100);
  };

  const mapChats = (chatArray, highlight = false) => {
    return chatArray?.map((user, i) => {
      if (!user.is_ad) {
        user.highlight = highlight;
        return (
          <UserCard
            key={i}
            user={user}
            selectedChats={selectedChats}
            i={i}
            setSelectedChats={setSelectedChats}
            onUserListClick={onUserListClick}
            callLogoutAPI={callLogoutAPI}
            clearConversation={clearConversation}
            select={select}
            chatDetail={chatDetail}
            setChatDetail={setChatDetail}
            fetchAllChats={fetchAllChats}
            setShowOTPModel={setShowOTPModel}
            searchResult={searchResult}
            highlight={highlight}
            search={search}
          />
        );
      }
    });
  };

  return (
    <ChatListContainer>
      {search?.trim() ? (
        <>
          <SearchByLabel>
            <FW700>Chats</FW700>
          </SearchByLabel>
          {!searchResult?.chats?.length ? (
            <XYCenteredContent justify="center" height="240px">
              <h5>No chats found</h5>
            </XYCenteredContent>
          ) : (
            mapChats(searchResult?.chats, "name")
          )}
          <SearchByLabel className="mt-3">
            <FW700>Messages</FW700>
          </SearchByLabel>
          {!searchResult?.messages?.length ? (
            <XYCenteredContent justify="center" height="240px">
              <h5>No messages found</h5>
            </XYCenteredContent>
          ) : (
            mapChats(searchByMessge, "message")
          )}
        </>
      ) : (
        mapChats(userData)
      )}

      {select && (
        <MultipleDeletion>
          <AiFillDelete onClick={deleteSelectedChats} />
        </MultipleDeletion>
      )}
    </ChatListContainer>
  );
}

export default ChatList;
