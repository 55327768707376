// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { disableNetwork, getFirestore } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { WEB_PUSH_CERTIFICATE } from "../config";
import { isIOS } from "../../components/CommonThings/CommonThings";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyBOxmXvNFiLuwii9ZCMFiKLaZvr5n9P4zo",
  authDomain: "chatmail-b272e.firebaseapp.com",
  projectId: "chatmail-b272e",
  storageBucket: "chatmail-b272e.appspot.com",
  messagingSenderId: "245807166112",
  appId: "1:245807166112:web:a45f485aa0f17fbf618fc5",
  measurementId: "G-6K83BQZYNT",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const messaging = getMessaging(app);
export const fireStoreDB = getFirestore(app);

export const onMessageListener = (setFirebaseForegroundMessage) => {
  onMessage(messaging, (payload) => {
    console.log("payload", payload);
    setFirebaseForegroundMessage(payload);
  });
};

export const tokenFromFirebase = async () => {
  try {
    var sw_activation;
    if (process.env.NODE_ENV === "production") {
      sw_activation = await navigator?.serviceWorker?.ready;
    }
    console.log(
      "service worker : ",
      navigator?.serviceWorker?.controller?.state
    );

    console.log("fetching token...");
    const tokenResponse = await getToken(messaging, {
      vapidKey: WEB_PUSH_CERTIFICATE,
    });

    if (tokenResponse) {
      return {
        status: 1,
        message: "token received from firebase.",
        data: {
          token: tokenResponse,
        },
      };
    } else {
      return {
        status: 0,
        message:
          "No registration token available. Request permission to generate one.",
      };
    }
  } catch (error) {
    const isiOSDevice = isIOS();
    if (isiOSDevice == "NO") {
      navigator.permissions
        .query({ name: "notifications" })
        .then((permission) => {
          if (permission.state === "denied") {
            return {
              status: 0,
              message:
                "To allow permission, click the symbol next to the URL and then change the setting to allow",
            };
          } else {
            Notification.requestPermission();

            return {
              status: 0,
              message: "Allow notification permission.",
            };
          }
        });
    }

    console.error("An error occurred while retrieving token. ", error);
    return {
      status: 0,
      message: "Unable to get registration token.",
      error,
    };
  }
};
