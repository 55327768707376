import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { customScroll } from "../../../../CommonThings/common.styled";

export const GroupInfoMain = styled(Modal)`
  .modal-dialog {
    @media (max-width: 575px) {
      width: 300px !important;
      margin: auto;
    }
    @media (max-width: 460px) {
      width: 280px !important;
      margin: auto;
    }
    @media (max-width: 280px) {
      width: 100% !important;
      margin: auto;
    }
  }
`;

export const MemberCount = styled.ul.attrs({
  className: "text-secondary ps-4",
})`
  font-size: 1rem;
  margin: 6px;
`;

export const MembersUL = styled.ul.attrs({
  className: "text-secondary text-start ps-0",
})`
  list-style: none;
  max-height: 400px;
  overflow: auto;
  ${customScroll()}
  @media(max-width:575px) {
    max-height: 200px;
  }
`;

export const GroupDesc = styled.p.attrs({
  className: "text-secondary text-center",
})`
  font-size: 1rem;
`;
