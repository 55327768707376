import { Button } from "react-bootstrap";
import styled from "styled-components";

export const ActionButton = styled(Button)`
  color: #2871d8;
  font-size: 25px;
  padding: 0 0 0 13px;

  svg {
    color: ${(props) => props.color} !important;
  }
`;

export const DeleteTitle = styled.h4`
  font-weight: bold;
  color: #214881;
`;

export const Reject = styled(Button)`
  background-color: rgb(33, 72, 129);
  border: none;
`;

export const Confirm = styled(Button)`
  border: none;
`;
