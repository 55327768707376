/* 
'firebase/firestore' (possible exports: AbstractUserDataWriter, Bytes, CACHE_SIZE_UNLIMITED, CollectionReference, DocumentReference, DocumentSnapshot, FieldPath, FieldValue, Firestore, FirestoreError, GeoPoint, LoadBundleTask, Query, QueryConstraint, QueryDocumentSnapshot, QuerySnapshot, SnapshotMetadata, Timestamp, Transaction, WriteBatch, _DatabaseId, _DocumentKey, _EmptyAppCheckTokenProvider, _EmptyAuthCredentialsProvider, _FieldPath, _cast, _debugAssert, _isBase64Available, _logWarn, _setIndexConfiguration, _validateIsNotUsedTogether, addDoc, arrayRemove, arrayUnion, clearIndexedDbPersistence, collection, collectionGroup, connectFirestoreEmulator, deleteDoc, deleteField, disableNetwork, doc, documentId, enableIndexedDbPersistence, enableMultiTabIndexedDbPersistence, enableNetwork, endAt, endBefore, ensureFirestoreConfigured, executeWrite, getDoc, getDocFromCache, getDocFromServer, getDocs, getDocsFromCache, getDocsFromServer, getFirestore, increment, initializeFirestore, limit, limitToLast, loadBundle, namedQuery, onSnapshot, onSnapshotsInSync, orderBy, query, queryEqual, refEqual, runTransaction, serverTimestamp, setDoc, setLogLevel, snapshotEqual, startAfter, startAt, terminate, updateDoc, waitForPendingWrites, where, writeBatch)

*/

import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { fireStoreDB } from "./firebaseConnection";

export const collectionName = "ChatMail_OTO";

// MERGE TYPING STATUS WITH FIRESTORE DATABASE (OF CURRENT USER(UID))
export const fireStoreUpdateDoc = async (chatId, updateData) => {
  try {
    if (chatId && updateData) {
      const userRef = doc(fireStoreDB, collectionName, chatId.toString());
      const response = await setDoc(
        userRef,
        { ...updateData, uid: Number(chatId), createdAt: new Date() },
        { merge: true }
      );
      return {
        status: 1,
        message: "Firebase user updated",
        data: { docId: chatId },
      };
    }
  } catch (error) {
    console.log(error);
    console.log(error.message);
    return {
      status: 0,
      message: "Firebase user did not update.",
    };
  }
};

export const getTypingUsers = async (reception_id) => {
  try {
    const q = query(
      collection(fireStoreDB, collectionName),
      where("receptionid", "==", reception_id)
    );

    var temp_arr = [];
    await getDocs(q).then((querySnapshot) => {
      querySnapshot.docs.map((doc) => {
        temp_arr.push(doc.data());
        return doc.data();
      });
    });
    return temp_arr.filter((doc) => doc.istyping);
  } catch (error) {
    console.error("getTypingUsersERROR", error);
    return {
      status: 0,
      message: "could not get requested data.",
    };
  }
};

export const fireStoreGetActivity = (docId, setFirestoreActivity) => {
  try {
    if (collectionName && docId && setFirestoreActivity) {
      const userRef = doc(fireStoreDB, collectionName, docId.toString());
      const unsub = onSnapshot(userRef, (doc) => {
        setFirestoreActivity && setFirestoreActivity(doc.data());
      });
    }
  } catch (error) {
    console.error("fireStoreSenderUpdatesERROR", error);
  }
};

export const fireStoreActivityWatcher = (docId, setUpdateSenderData) => {
  try {
    const q = query(
      collection(fireStoreDB, collectionName),
      where("uid", "==", docId)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          console.log("New city: ", change.doc.data());
        }
        if (change.type === "modified") {
          console.log("Modified city: ", change.doc.data());
          setUpdateSenderData(change.doc.data());
        }
        if (change.type === "removed") {
          console.log("Removed city: ", change.doc.data());
        }
      });
    });
  } catch (error) {
    console.error("fireStoreActivityWatcherERROR", error);
  }
};
