import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { GoReply } from "react-icons/go";
import { MdDownload } from "react-icons/md";
import { BsChevronDown } from "react-icons/bs";
import { CgAttachment } from "react-icons/cg";

import { getTimeFormat } from "../../CommonThings/CommonThings";
import { ATTACHMENT_URL } from "../../../Auth/config";
import { toast } from "react-toastify";
import {
  deleteChatMessage,
  getAllGroupChats,
  getChat,
  updateGroupChat,
} from "../../../IndexedDB/queries";
import { AiFillDelete } from "react-icons/ai";
import MessageSelectionInput from "./MessageSelection/MessageSelectionInput";
import { FW700, XYCenteredContent } from "../../CommonThings/common.styled";
import {
  AudioFile,
  ChatBoxMain,
  DeleteMessage,
  DeleteMessageContainer,
  DeleteSelectedMessages,
  DownloadDocument,
  DownloadImage,
  ImageFile,
  MessageContainer,
  MessageContainerMain,
  MessageTimeStatus,
  RepliedMessageBody,
  RepliedMessageContainer,
  ReplyButtonContainer,
  SenderLabelContainer,
  StatusSending,
  StatusSent,
  SubjectText,
  VideoFile,
} from "./chatBox.styled";
import { logDOM } from "@testing-library/react";

function ChatBox({
  chatDetail,
  select,
  setReply,
  chatMessages,
  setSelect,
  sidebarRef,
  setGroupChatMessages,
  notificationPermissionState,
  messageref,
  searchMode,
}) {
  const [messageID, setMessageID] = useState(null);
  const [replyHighlight, setReplyHighlight] = useState("");
  const [selectedMessages, setSelectedMessages] = useState([]);

  const [preventHighlight, setPreventHighlight] = useState(chatMessages.length);
  const [highlightFoundMessage, setHighlightFoundMessage] = useState(
    "bg-primary bg-opacity-25"
  );

  useEffect(() => {
    if (!preventHighlight) {
      setTimeout(() => {
        setHighlightFoundMessage("");
      }, 2000);
      if (!highlightFoundMessage && chatDetail?.highlight === "message") {
        setHighlightFoundMessage("bg-primary bg-opacity-25");
      }
    }
  }, [searchMode, chatDetail]);

  useEffect(() => {
    setPreventHighlight();
  }, [chatMessages.length]);

  const deleteSingleMessage = async () => {
    try {
      const was_deleted = await deleteChatMessage({
        msg_id: messageID,
        chatDetail,
      });

      if (was_deleted.status === 1) {
        await sidebarRef.current.fetchAllChats(
          chatDetail.userData ? chatDetail.userData.webID : null,
          chatDetail.groupData ? chatDetail.groupData.groupID : null
        );
        toast.success(was_deleted.message);

        if (chatDetail.groupData) {
          chatMessages = chatMessages.filter((m) => m.id != messageID);
          setGroupChatMessages(chatMessages);
        }
      } else {
        toast.error(was_deleted.message);
      }
    } catch (error) {
      console.error("deleteSingleMessageERROR", error);
    }
  };

  //Escape Key Handler
  const keyDownHandler = (event) => {
    if (event.key === "Escape") {
      event.preventDefault();
      setSelect(false);
      setSelectedMessages([]);
      setMessageID(null);
    }
  };

  useEffect(() => {
    document?.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  // High light message
  useEffect(() => {
    if (replyHighlight) {
      setTimeout(() => {
        setReplyHighlight("");
      }, 2000);
    }
  }, [replyHighlight]);

  const repliedMessageFile = (filePath) => {
    const [type, name] = filePath.split("/").slice(-2);
    return `${type} file -  ${name}`;
  };

  const downloadImage = (url) => {
    saveAs(url);
  };

  const deleteSelectedMessages = async (msg_arr) => {
    let temp_arr;
    try {
      temp_arr = await msg_arr.map(async (msg_id) => {
        return await deleteChatMessage({ msg_id, chatDetail });
      });
      temp_arr = await Promise.all(temp_arr).then((values) => {
        return values;
      });
      if (temp_arr.find((el) => el.status !== 0)) {
        if (chatDetail.groupData) {
          const { groupID } = chatDetail.groupData;

          //update GroupChat doc
          const all_group_chats = await getAllGroupChats();

          const current_group_chat = all_group_chats.data.find(
            (el) => el.group_id == groupID
          );

          const updated_chat_ids = current_group_chat.chat_ids.filter(
            (el) => !msg_arr.includes(el)
          );

          const update_group_chat = await updateGroupChat({
            ...current_group_chat,
            chat_ids: updated_chat_ids,
          });
          let updated_msg_arr = await updated_chat_ids.map(async (id) => {
            let msg = await getChat(id);
            return msg.data;
          });

          updated_msg_arr = await Promise.all(updated_msg_arr).then(
            (values) => {
              return values;
            }
          );

          setGroupChatMessages(updated_msg_arr);
        }

        toast.success("Messages deleted successfully !");
        await sidebarRef.current.fetchAllChats(
          chatDetail.userData ? chatDetail.userData.webID : null,
          chatDetail.groupData ? chatDetail.groupData.groupID : null
        );
      } else {
        toast.error("Error occured while deleting message !!");
      }
      setSelectedMessages([]);
    } catch (error) {
      console.error("deleteSelectedMessagesERROR", error);
    }
  };

  const memberLabelColor = (message_data) => {
    const currentMember =
      chatDetail?.groupData &&
      chatDetail?.groupData?.members?.find(
        (member) =>
          member.id == message_data.api_response.sendingUserID ||
          member.ID == message_data.api_response.sendingUserID
      );
    return currentMember?.color;
  };

  return (
    <>
      {chatMessages?.length
        ? chatMessages.map(
            (data, i) =>
              data && (
                <ChatBoxMain
                  is_ad={chatDetail?.is_ad}
                  role={data?.role}
                  key={i}
                  onClick={() => messageID && setMessageID(null)}
                  id={
                    !chatDetail?.is_ad
                      ? data?.api_response?.uniqueMessageID
                      : data?.title
                  }
                  ref={
                    searchMode === "true" &&
                    chatDetail?.highlight === "message" &&
                    chatDetail?.foundMessage?.api_response?.uniqueMessageID ===
                      data?.api_response?.uniqueMessageID
                      ? messageref
                      : null
                  }
                  className={`mt-3 ${
                    replyHighlight &&
                    replyHighlight === data?.api_response?.uniqueMessageID
                      ? "bg-primary bg-opacity-25"
                      : ""
                  }
                  ${
                    searchMode === "true" &&
                    chatDetail?.foundMessage?.api_response?.uniqueMessageID ===
                      data?.api_response?.uniqueMessageID
                      ? highlightFoundMessage
                      : ""
                  }
                  `}
                >
                  {select && data?.role !== "user" && (
                    <MessageSelectionInput
                      chatDetail={chatDetail}
                      setSelectedMessages={setSelectedMessages}
                      selectedMessages={selectedMessages}
                      data={data}
                      me={true}
                      i={i}
                    />
                  )}
                  <label htmlFor={i}>
                    <MessageContainerMain
                      role={data?.role}
                      className={`position-relative
                      ${chatDetail?.is_ad && "rounded"}`}
                    >
                      {/* Reply things */}
                      {!chatDetail?.is_ad && (
                        <SenderLabelContainer
                          justify={
                            data?.role == "user" ? "flex-end" : "space-between"
                          }
                        >
                          {data?.role == "sender" && (
                            <FW700
                              style={{
                                color: memberLabelColor(data),
                              }}
                            >
                              {chatDetail?.groupData &&
                                (data?.api_response?.SenderName || "Unknown")}
                            </FW700>
                          )}
                          <BsChevronDown
                            style={{ width: "12px" }}
                            onClick={() => {
                              notificationPermissionState === "granted" &&
                                setMessageID(data?.id);
                            }}
                          />
                        </SenderLabelContainer>
                      )}
                      {data?.reply?.id && (
                        <RepliedMessageContainer role={data?.role}>
                          <a
                            href={`#${data.api_response?.repliedMessageID}`}
                            onClick={() =>
                              setReplyHighlight(
                                data.api_response?.repliedMessageID
                              )
                            }
                          >
                            {data?.reply.role === "user"
                              ? "You"
                              : data.api_response?.title}
                            <MessageContainer>
                              {data?.reply?.api_response?.subject && (
                                <SubjectText>
                                  Subject: {data?.reply?.api_response?.subject}
                                </SubjectText>
                              )}
                              {data?.reply?.api_response?.body ? (
                                <RepliedMessageBody>
                                  {data?.reply?.api_response?.body}
                                </RepliedMessageBody>
                              ) : (
                                ""
                              )}
                              <div>
                                {Number(data?.reply?.api_response?.typeID) ===
                                  2 &&
                                  repliedMessageFile(
                                    data?.reply?.api_response?.filePath
                                  )}
                              </div>

                              {Number(data?.reply?.api_response?.typeID) ===
                                3 &&
                                repliedMessageFile(
                                  data?.reply?.api_response?.filePath
                                )}

                              {Number(data?.reply?.api_response?.typeID) ===
                                4 &&
                                repliedMessageFile(
                                  data?.reply?.api_response?.filePath
                                )}

                              {Number(data?.reply?.api_response?.typeID) ===
                                5 &&
                                repliedMessageFile(
                                  data?.reply?.api_response?.filePath
                                )}
                            </MessageContainer>
                          </a>
                        </RepliedMessageContainer>
                      )}
                      {/* Chat message component */}
                      <MessageContainer>
                        {(data?.api_response?.subject || data?.title) && (
                          <SubjectText>
                            Subject:{" "}
                            {data?.api_response?.subject || data?.title}
                          </SubjectText>
                        )}
                        {Number(data?.api_response?.typeID) === 2 && (
                          <AudioFile
                            className="w-100"
                            controls
                            src={data?.api_response?.filePath}
                          />
                        )}
                        {Number(data?.api_response?.typeID) === 3 && (
                          <VideoFile
                            src={data?.api_response?.filePath}
                            controls
                          >
                            Your browser does not support the video tag.
                          </VideoFile>
                        )}
                        {(Number(data?.api_response?.typeID) === 4 ||
                          data?.image) && (
                          <div className="position-relative d-inline-block w-100">
                            <ImageFile
                              src={
                                data?.api_response?.filePath
                                  ? data?.api_response?.filePath
                                  : data?.image
                              }
                              alt="image"
                              className="img-fluid"
                            />
                            <DownloadImage
                              onClick={() =>
                                downloadImage(
                                  data?.image || data?.api_response?.filePath
                                )
                              }
                            >
                              <MdDownload />
                            </DownloadImage>
                          </div>
                        )}
                        {Number(data?.api_response?.typeID) === 5 && (
                          <XYCenteredContent className="gap-2">
                            <div>
                              <label>
                                <CgAttachment size={20} />
                              </label>
                            </div>
                            <div>
                              {data?.api_response?.filePath &&
                                repliedMessageFile(
                                  data?.api_response?.filePath
                                )}
                            </div>
                            <div>
                              <DownloadDocument
                                onClick={() =>
                                  downloadImage(data?.api_response?.filePath)
                                }
                              >
                                <MdDownload />
                              </DownloadDocument>
                            </div>
                          </XYCenteredContent>
                        )}
                        {data?.api_response?.body ? (
                          <p>{data?.api_response?.body}</p>
                        ) : (
                          data?.link && (
                            <p>
                              <a
                                href={
                                  data?.link.includes("http")
                                    ? data?.link
                                    : `http://${data?.link}`
                                }
                                target={"_blank"}
                              >
                                Click here for more...
                              </a>
                            </p>
                          )
                        )}
                      </MessageContainer>
                      <MessageTimeStatus className="user-select-none">
                        {data?.api_response?.uniqueMessageID &&
                          (data?.api_response?.sentTime || data?.currentTime) &&
                          getTimeFormat(
                            data?.api_response?.sentTime || data?.currentTime
                          )}
                        {data?.role === "user" &&
                          (data.status ? <StatusSent /> : <StatusSending />)}
                      </MessageTimeStatus>
                      {data?.api_response?.uniqueMessageID && (
                        <ReplyButtonContainer
                          role={data?.role}
                          onClick={() =>
                            notificationPermissionState === "granted" &&
                            setReply(data)
                          }
                        >
                          <label>
                            <GoReply />
                          </label>
                        </ReplyButtonContainer>
                      )}
                      {!chatDetail?.is_ad && (
                        <DeleteMessageContainer
                          role={data?.role}
                          active={data?.id === messageID}
                        >
                          <DeleteMessage
                            role={data?.role}
                            onClick={(i) => deleteSingleMessage(i)}
                          >
                            Delete
                          </DeleteMessage>
                        </DeleteMessageContainer>
                      )}
                    </MessageContainerMain>
                  </label>
                  {select && data?.role === "user" && (
                    <MessageSelectionInput
                      chatDetail={chatDetail}
                      setSelectedMessages={setSelectedMessages}
                      selectedMessages={selectedMessages}
                      data={data}
                      me={false}
                      i={i}
                    />
                  )}
                </ChatBoxMain>
              )
          )
        : ""}
      {selectedMessages.length !== 0 && (
        <DeleteSelectedMessages>
          <AiFillDelete
            onClick={() => {
              deleteSelectedMessages(selectedMessages);
              setSelect(false);
            }}
          />
        </DeleteSelectedMessages>
      )}
    </>
  );
}

export default ChatBox;
