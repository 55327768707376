import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { getGroupInfo } from "../../../../../Auth/api";
import {
  ProfileImage,
  UserName,
  XYCenteredContent,
} from "../../../../CommonThings/common.styled";
import {
  GroupDesc,
  GroupInfoMain,
  MemberCount,
  MembersUL,
} from "./groupInfoModal.styled";
import GroupMembers from "./GroupMembers/GroupMembers";
import { updateGroup } from "../../../../../IndexedDB/queries";
import { UserCardContext } from "../UserCard";

function GroupInfoModal({
  showGroupInfo,
  handleClose,
  group,
  setProfileImage,
}) {
  const [showMenu, setShowMenu] = useState(false);
  const [groupNameWidth, setGroupNameWidth] = useState("90px");

  const [members, setMembers] = useState([]);
  const [groupProfileImage, setGroupProfileImage] = useState(
    group?.groupData?.profilePic
  );
  const { search } = useContext(UserCardContext);

  useEffect(() => {
    if (showGroupInfo) {
      getUpdatedGroupInfo();
    }
  }, [showGroupInfo]);

  useEffect(() => {
    if (!search) {
      getUpdatedGroupInfo(true);
    }
  }, []);

  const getUpdatedGroupInfo = async (save_data = false) => {
    const group_info = await getGroupInfo({
      sessionId: Number(group?.groupData?.contactID),
      groupId: Number(group?.groupData?.groupID),
    });
    if (group_info?.status === 200) {
      group_info?.data?.members?.unshift(group_info?.data?.owner);
      setMembers(group_info?.data?.members);
      setGroupProfileImage(group_info?.data?.profilePic);
      setProfileImage((prev) => group_info?.data?.profilePic || prev);
      if (save_data) {
        const updated_data = {
          ...group,
          groupData: {
            ...group?.groupData,
            members: group_info?.data?.members,
          },
        };
        await updateGroup(updated_data);
      }
    }
  };

  return (
    <GroupInfoMain
      show={showGroupInfo}
      onHide={() => {
        handleClose();
        showMenu && setShowMenu(false);
      }}
      size="sm"
      centered
      onClick={() => {
        showMenu && setShowMenu(false);
      }}
    >
      <Modal.Body className="user-select-none px-0">
        <XYCenteredContent justify="center">
          <ProfileImage
            src={groupProfileImage || "./images/g_icon.ico"}
            alt="No Image"
            width={"200px"}
            height={"200px"}
          />
        </XYCenteredContent>
        <Modal.Title>
          <XYCenteredContent
            justify="center"
            dir={!groupNameWidth ? "column" : ""}
            className={!groupNameWidth && "mt-2"}
          >
            <UserName
              width={groupNameWidth}
              title={group?.groupData?.groupName}
              onClick={() => setGroupNameWidth(groupNameWidth ? "" : "90px")}
            >
              {group?.groupData?.groupName}
            </UserName>
            <MemberCount>
              <li>
                <span>{group?.groupData?.members?.length} members</span>
              </li>
            </MemberCount>
          </XYCenteredContent>
          <GroupDesc>{group?.groupData?.groupDescription}</GroupDesc>
        </Modal.Title>
        <MembersUL>
          {members?.map((m, i) => (
            <GroupMembers
              m={m}
              key={i}
              contactID={group?.groupData?.contactID}
            />
          ))}
        </MembersUL>
      </Modal.Body>
    </GroupInfoMain>
  );
}

export default GroupInfoModal;
