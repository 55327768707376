import styled from "styled-components";

export const LoadingMain = styled.div.attrs({
  className: "d-flex align-items-center justify-content-center",
})`
  height: 100vh;
`;

export const SplashMain = styled.div`
  width: 450px;
  max-width: 100%;
  text-align: center;

  @media (max-width: 425px) {
    width: 280px;
    max-width: 100%;
  }
`;

export const SplashTitle = styled.div`
  margin-top: 30px;
  color: #0d213d;
`;

export const SplashTitleSpan = styled.span`
  color: #b3c1cc;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
`;

export const SplashLogoContainer = styled.div`
  padding-bottom: 30px;
  border-bottom: 1px solid #d5e0e9;
`;
