import styled from "styled-components";
import { XYCenteredContent, customScroll } from "../CommonThings/common.styled";
import { Button, Dropdown } from "react-bootstrap";
import { BiSelectMultiple } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";

export const dottedLongText = (hideLines) =>
  `overflow: hidden;display: -webkit-box;-webkit-line-clamp: ${hideLines};-webkit-box-orient: vertical;`;

export const ChatMain = styled.div`
  background-color: #f1f5fa;
  width: 100%;
  margin-left: 480px;
  height: calc(100vh - 86px);
  transition: 0.5s;

  @media (max-width: 1024px) {
    width: 100%;
    margin-left: 0;
  }

  @media (max-width: 540px) {
    height: 100vh;
  }
`;

export const ChatHeader = styled(XYCenteredContent)`
  background-color: #fff;
  box-shadow: 3px 3px 8px #d5e0e9;
  border-left: 2px solid #d5e0e9;
  width: 100%;
  height: 80px;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
  position: sticky;
  top: 0;
  z-index: 2;

  @media (max-width: 445px) {
    padding: 0 10px !important;
  }
`;

export const ChatDetailWrap = styled(XYCenteredContent).attrs({
  className: "gap-3",
})`
  @media (max-width: 320px) {
    column-gap: 8px !important;
  }
`;

export const SidebarToggle = styled(Button)`
  border: none;
  background-color: transparent !important;
  box-shadow: none !important;

  display: none;
  border: none;

  @media (max-width: 1024px) {
    display: block;
  }
`;

export const ChatDetail = styled.div.attrs({
  className: "ms-3 d-flex w-100 align-items-start flex-column",
})``;

export const NameDesc = styled(XYCenteredContent)`
  span {
    font-size: 18px;
    color: #0d213d;
    font-weight: 600;
    line-height: 22px;
    max-width: 250px;
    display: block;
    ${dottedLongText(1)}
  }

  h6 {
    margin: 0 !important;
    color: #0d213d;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }

  @media (max-width: 768px) {
    flex-direction: column !important;
    align-items: start !important;

    h6 {
      margin-left: 6px !important;
    }

    span {
      margin-left: 7px;
    }
  }

  @media (max-width: 540px) {
    span,
    h6 {
      font-size: 16px;
    }
  }

  @media (max-width: 445px) {
    margin-left: 2px !important;
  }
`;

export const UserActions = styled(XYCenteredContent).attrs({
  className: "gap-5",
})`
  button {
    background-color: transparent !important;
    border: none;
    padding: 0;
    box-shadow: none !important;
  }
`;

export const ActionsDropDown = styled(Dropdown.Menu).attrs({
  className: "py-3",
})`
  background-color: #ffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  border: none;
  width: 260px;
  height: auto;
  margin-top: 12px;

  button {
    display: flex;
    align-items: center;
    color: #0d213d;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    gap: 15px;
    margin: 20px 0;
  }

  button:nth-child(2) {
    display: none !important;
  }

  @media (max-width: 512px) {
    button:nth-child(1) img {
      margin-right: 17px;
    }

    button:nth-child(2) {
      display: block !important;
    }

    button:nth-child(2) img {
      margin-right: 10px;
    }
  }
`;

export const UserActionButton = styled(Button).attrs({
  className: "my-0 w-100 px-3 py-3",
})``;

export const SelectMultiple = styled(BiSelectMultiple)`
  font-size: 1.4rem;
  color: #214881;
`;

export const ClearChat = styled(AiOutlineDelete).attrs({
  className: "text-danger",
})`
  font-size: 1.4rem;
`;

export const ChatBoxWrapper = styled.div.attrs({
  className: `px-4 py-3 ${(props) => props?.is_ad && "text-center"}`,
})`
  scroll-behavior: smooth;
  overflow: auto;
  background-color: #f1f5fa;
  height: ${(props) => (props?.is_ad ? "100%" : "calc(100vh - 160px)")};
`;

export const MembersTyping = styled.h6.attrs({
  className: "text-secondary",
})`
  font-size: 0.8rem;
  position: relative;
  bottom: 24px;
  left: 8px;
  width: 90%;
  font-weight: 600;
`;

export const MessageInputWrapper = styled(XYCenteredContent).attrs({
  className: "px-5 gap-3",
})`
  width: calc(100% - 480px);
  height: auto !important;
  padding: 15px;
  background-color: #fff;
  box-shadow: 3px -3px 8px #d5e0e9;
  border-left: 2px solid #d5e0e9;
  position: fixed;
  bottom: 0;

  button {
    border: none;
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 0px;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 15px !important;
  }
`;

export const SelectFiles = styled(Dropdown.Menu).attrs({
  className: "px-3",
})`
  background-color: #ffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  position: absolute;
  left: 0;
  width: 206px;
  height: 250px;
  bottom: 43px;
  row-gap: 20px;
  z-index: 5;

  @media (max-width: 445px) {
    width: 206px;
  }

  label {
    border: 0 !important;
    box-shadow: none !important;
    background-color: white;
    color: #0d213d;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    gap: 15px;
    display: flex;
    margin: 30px 0;
  }

  svg {
    font-size: 1.4rem;
    color: #223f6a;
    margin-inline: 4%;
  }

  label:last-child {
    margin-bottom: 0;
  }
`;

export const SelectedData = styled(XYCenteredContent).attrs({
  className: "position-relative",
})`
  width: 100%;
  height: 56px;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
    #ffffff;
  padding: 16px 12px 16px 16px;
  border: 1px solid #d5e0e9;
  border-radius: 8px;
  outline: none;

  input {
    border: none;
    outline: none;
    padding: 0;
  }

  ${(props) =>
    props.showSubject
      ? `
    ::after {
      position: absolute;
      content: "";
      width: 2px;
      height: 100%;
      background-color: #ede9e9;
      z-index: 3;
      top: 0;
    left: 212px;
    
    @media (max-width: 540px) {
        width: 100%;
        height: 2px !important;
        left: 0 !important;
        top: 50%;
      }
    }`
      : "content: none;"}

  @media (max-width: 540px) {
    flex-direction: column !important;
    height: auto !important;
    row-gap: 30px;
    position: relative;

    input {
      width: 100%;
      padding-right: 40px;
    }
  }
`;

export const ContentBarMain = styled.div`
  position: absolute;
  width: 100%;
  bottom: 55px;
  left: 0;
`;

export const ContentBarWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ContentToBeSent = styled(XYCenteredContent)`
  width: 100%;
  min-height: 60px;
  background-color: #fff;
  border: 1px solid #d5e0e9;
  border-radius: 8px 8px;
  padding: 2px 12px;
  ${(props) =>
    props.reply_id && props.containsMembers
      ? "bottom: 171px !important;"
      : (props.reply_id || props.containsMembers) &&
        "bottom: 112px !important;"}

  h6 {
    color: #0d213d;
    font-weight: bold;
  }

  span {
    font-size: 12px;
    color: grey;
    word-break: break-all;
    overflow: hidden;
    ${dottedLongText(1)}
  }
`;

export const CloseContentBar = styled(Button)`
  position: absolute;
  top: -10px;
  color: #000 !important;
  right: 2px;
  font-size: 26px;
  width: 10px;
  height: 10px;
`;

export const ReplyImage = styled.img`
  margin-right: 10px;
  height: 40px;
`;

export const CancelButton = styled.button`
  @media (max-width: 540px) {
    position: absolute;
    right: 0;
    bottom: 7px;
  }
`;

export const CancelImage = styled.img.attrs({ src: "./images/ic_cancel.ico" })`
  width: 43px;
  ${(props) => props.showSubject && "transform: rotate(45deg);"}
`;

export const NotificationBody = styled.h6`
  ${dottedLongText(3)}
`;

export const NotificationTitle = styled.h4`
  ${dottedLongText(2)}
`;
