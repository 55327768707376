import { Button } from "react-bootstrap";
import { BsCheck2All, BsClock } from "react-icons/bs";
import styled from "styled-components";
import { XYCenteredContent } from "../../CommonThings/common.styled";
import { dottedLongText } from "../chat.styled";

export const DeleteSelectedMessages = styled.div`
  display: flex !important;
  position: fixed;
  padding: 4px;
  top: 790px;
  right: 36px;
  font-size: 2rem;
  justify-content: end;
  background: rgba(0, 0, 0, 0);

  svg {
    width: 40px;
    height: 40px;
    padding: 8px;
    background-color: #8baaffa1;
    color: #ff0000d3;
    border-radius: 50%;
    transition: all 0.1s;
  }

  svg:hover {
    width: 44px;
    height: 44px;
    cursor: pointer;
    padding: 8px;
    background-color: rgb(190 207 255 / 88%);
    color: #ff0000;
    border-radius: 50%;
  }
`;

export const DeleteMessage = styled(Button)`
  position: relative !important;
  background-color: white !important;
  border: none;

  ::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    background-image: url(../../assets/img/up-arrow-delete.png);
    background-size: 16px;
    top: -13px;
    ${(props) => (props.role === "user" ? "right: 9px;" : "left: 9px;")}
    background-repeat: no-repeat;
  }
`;

export const DeleteMessageContainer = styled.div`
  position: absolute;
  top: 28px;
  right: ${(props) => (props.role === "user" ? "-4px" : "-106px")};
  z-index: 5;
  display: ${(props) => (props.active ? "flex" : "none")};

  .btn {
    padding: 0.375rem 2.75rem;
    margin-top: 5px;
    color: #112d54;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 16%);
    font-weight: 600;
  }
`;

export const ReplyButtonContainer = styled.div`
  position: absolute;
  width: 40px;
  ${(props) => (props.role === "sender" ? "right: -51px;" : "left: -51px;")}
  height: 40px;
  background-color: #c2c2c2;
  top: 50%;
  transform: translate(0, -50%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 23px;
  cursor: pointer;
  color: #fff;
  ${(props) => props.role === "sender" && "color: #fff;"}

  label {
    cursor: pointer;
  }
`;

export const StatusSending = styled(BsClock)`
  font-size: 1rem;
  margin-bottom: 2px;
  margin-left: 4px;
`;

export const StatusSent = styled(BsCheck2All)`
  font-size: 1.2rem !important;
  margin-bottom: 1px;
  margin-left: 2px;
`;

export const MessageTimeStatus = styled.span`
  font-size: 12px;
  bottom: 4px;
  right: 8px;
  position: absolute;
`;

export const DownloadDocument = styled.label`
  width: 40px;
  height: 40px;
  background-color: #d2d2d285;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  border-radius: 50%;
  color: #fff !important;
  cursor: pointer;
`;

export const DownloadImage = styled.label`
  width: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 40px;
  background-color: #d2d2d285;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  border-radius: 50%;
  color: #fff !important;
`;

const mediaStyle = "width: 100%; max-height: 250px; object-fit: cover;";

export const VideoFile = styled.video`
  ${mediaStyle}
`;

export const ImageFile = styled.img`
  ${mediaStyle}

  @media (max-width: 540px) {
    max-width: 180px;
  }
`;

export const AudioFile = styled.audio`
  margin: 0.5rem auto;
  min-width: 345px;

  @media (max-width: 470px) {
    margin: 0.5rem auto;
    max-width: 200px;
    min-width: 200px;
  }
`;

export const SubjectText = styled.p`
  font-size: 12px;
  position: relative;
  margin-bottom: 15px;
  padding-right: 50px;

  ::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    left: 0;
    bottom: -6px;
    background-color: rgba(255, 255, 255, 0.336);
  }
`;

export const MessageContainer = styled.div`
  align-items: center;
  column-gap: 10px;
  word-break: break-all;
`;

export const RepliedMessageBody = styled.p`
  word-wrap: break-word;
  ${dottedLongText(2)}
`;

export const RepliedMessageContainer = styled.div`
  background: ${(props) => (props.role === "user" ? "#223f6a" : "#eaeaea")};
  padding: 7px;
  border-radius: 6px;

  a {
    text-decoration: none;
    color: ${(props) => (props.role === "user" ? "#fff" : "#000")};
  }
`;

export const SenderLabelContainer = styled(XYCenteredContent)`
  cursor: pointer;
  margin-top: -9px;
  ${(props) => (props?.typeID == 3 || props?.typeID == 4) && "height: 28px;"}
`;

const user_message_style =
  "max-width: 500px;  min-width: 90px;  background-color: #262a50;  padding: 12px 12px 20px 12px;  border-radius: 12px 12px 0px 12px;  color: #fff;";
const sender_message_style =
  "max-width: 500px;  min-width: 90px;  background-color: #fff;  padding: 12px 12px 20px 12px;  border-radius: 0px 12px 12px 12px;  color: #0d213d;  position: relative;  font-weight: 400;  font-size: 16px;  line-height: 20px;";

export const MessageContainerMain = styled.div`
  ${(props) =>
    props.role === "user" ? user_message_style : sender_message_style}
`;

export const ChatBoxMain = styled.div`
  border-radius: 15px;
  ${(props) =>
    `${
      props.role === "user"
        ? "padding-left: 50px; justify-content: flex-end; width: 100%;"
        : "padding-right: 50px; justify-content: flex-start;"
    } ${
      props.is_ad
        ? "display: inline-block; padding-right: 44px !important; @media (max-width: 540px) {width: 100%; padding-right: 0 !important;}"
        : "display: flex; align-items: center;"
    }`}
`;
