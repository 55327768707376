import React, { useEffect, useState } from "react";
import { fireStoreGetActivity } from "../../../../../../Auth/firebase/firebaseQueries";
import {
  OnlineStatus,
  ProfileImage,
  ProfileImageContainer,
  XYCenteredContent,
} from "../../../../../CommonThings/common.styled";
import {
  MemberBadge,
  MemberCard,
  MemberText,
  MemberTextContainer,
} from "./groupMembers.styled";

function GroupMembers({ m, contactID }) {
  const [memberActivity, setMemberActivity] = useState({});
  const [profileImage, setProfileImage] = useState("../images/user.ico");

  useEffect(() => {
    if (m) {
      setProfileImage(
        m?.profile || m?.picturePath || m?.profilePic || "../images/user.ico"
      );
      fireStoreGetActivity(m.ID || m.id, setMemberActivity);
    }
  }, [m]);

  return (
    <MemberCard>
      <XYCenteredContent justify="space-between">
        <ProfileImageContainer>
          <OnlineStatus
            isOnline={
              Number(memberActivity?.uid) === Number(m.ID || m.id) &&
              memberActivity?.islive === "active"
            }
            isGroupMember
          />
          <ProfileImage src={profileImage} alt="" width="48px" height="48px" />
        </ProfileImageContainer>
        <MemberTextContainer>
          <MemberText>{contactID == m.ID ? "You" : m.name}</MemberText>
          <MemberText>
            {(m.phoneWithCcode && `+${m.phoneWithCcode}`) || m.email}
          </MemberText>
        </MemberTextContainer>
      </XYCenteredContent>
      {m.isAdmin || m.role == "admin" ? (
        <MemberBadge>{m.isOwner ? "Admin" : "Manager"}</MemberBadge>
      ) : (
        ""
      )}
    </MemberCard>
  );
}

export default GroupMembers;
