import styled from "styled-components";
import { APP_LOGO } from "../../Auth/config";
import { IoIosPause, IoIosPlay } from "react-icons/io";

export const customScroll = (width) =>
  `::-webkit-scrollbar {
    width: ${width || "8"}px;
  }

  ::-webkit-scrollbar-track {
    padding: 10px;
    border-radius: 5px;
    background: #f1f1f1;
    border-right: 1px solid #d5e0e9;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #262a5070;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #262a50cf;
  }`;

export const AppLogo = styled.img.attrs({ src: `./images/${APP_LOGO}` })``;
export const XYCenteredContent = styled.div`
  display: flex;
  flex-direction: ${(props) => props.dir || "auto"};
  align-items: center !important;
  justify-content: ${(props) => props.justify} !important;
  height: ${(props) => props.height || "auto"};
`;

export const ProfileImageContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const ProfileImage = styled.img.attrs((props) => ({
  src: props.src,
}))`
  border-radius: 50%;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

export const OnlineStatus = styled.div`
  ${(props) =>
    props.isOnline
      ? `width: ${props.isGroupMember ? 12 : 14}px;
         height: ${props.isGroupMember ? 12 : 14}px;
         border-radius: 50%;
         border: 2px solid #fff;
         background-color: #53c92a;
         position: absolute;
         top: 2px;
         right: 0px;

         @media (max-width: 512px) {
         top: -1px;
         right: -1px;
        }`
      : ""}
`;

export const UserTyping = styled.span`
  font-size: 16px !important;
  color: #6c757d !important;
`;

export const UserCardContainer = styled.div.attrs({
  className: "px-4 pt-3 d-flex align-items-center gap-3",
})`
  border-bottom: 1px solid #d5e0e9;
  padding-bottom: 8px;
`;

export const UserName = styled.div`
  color: #0d213d;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  max-width: ${(props) => props.width || "250px"};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const UserLastMessageContainer = styled.div.attrs({
  className: "d-flex align-items-center gap-3",
})``;

export const UserLastMessage = styled(UserName)`
  font-size: 16px !important;

  @media (max-width: 476px) {
    max-width: 10rem;
  }
  @media (max-width: 426px) {
    max-width: 8rem;
  }
  @media (max-width: 395px) {
    max-width: 6rem;
  }
  @media (max-width: 374px) {
    max-width: 4rem;
  }
  @media (max-width: 348px) {
    max-width: 2rem;
  } ;
`;

export const CheckBox = styled.input.attrs({
  type: "checkbox",
  className: `${(props) => (props.me ? "me-2" : "ms-2")}`,
})`
  width: 40px;
  height: 20px;
  display: ${(props) => props.hide && "none"};
`;

export const FW700 = styled.span`
  font-weight: 600 !important;
`;

export const AddFiles = styled.div`
  position: relative;
`;

export const SendInputContainer = styled(XYCenteredContent).attrs({
  className: "px-4 gap-3",
})`
  width: calc(100% - 480px);
  height: auto !important;
  padding: 15px;
  background-color: #fff;
  box-shadow: 3px -3px 8px #d5e0e9;
  border-left: 2px solid #d5e0e9;
  position: fixed;
  bottom: 0;

  button {
    border: none;
    background-color: transparent !important;
    box-shadow: none !important;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 18px !important;
  }
`;

// export const Pause = styled.img.attrs({ src: "./images/pause.ico" })``;
export const Play = styled(IoIosPlay)`
  color: #262a50;
  font-size: 2rem;
  position: relative;
  right: 8px;
`;
export const Pause = styled(IoIosPause)`
  color: #262a50;
  font-size: 2rem;
  position: relative;
  right: 8px;
`;
export const Delete = styled.img.attrs({ src: "./images/delete.ico" })``;
