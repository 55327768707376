import React from "react";
import { FaLock } from "react-icons/fa";
import { WEB_ACCESS_TYPE } from "../../Auth/config";
import { AppLogo } from "../CommonThings/common.styled";
import {
  LoadingMain,
  SplashLogoContainer,
  SplashMain,
  SplashTitle,
  SplashTitleSpan,
} from "./loading.styled";

const Loading = () => {
  return (
    <LoadingMain>
      <SplashMain>
        <SplashLogoContainer>
          <AppLogo />
        </SplashLogoContainer>
        <SplashTitle>
          <h4>{WEB_ACCESS_TYPE}</h4>
          <SplashTitleSpan>
            <FaLock /> End-to-end encrypted
          </SplashTitleSpan>
        </SplashTitle>
      </SplashMain>
    </LoadingMain>
  );
};

export default Loading;
