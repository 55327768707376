import React from "react";
import Modal from "react-bootstrap/Modal";
import { AppLogo, XYCenteredContent } from "../../CommonThings/common.styled";
import { isIOS } from "../../CommonThings/CommonThings";
import { CenteredLogo } from "./iOSUserInfo.styled";

export default function IOSUserInfo() {
  return (
    <Modal show={isIOS() === "YES"} centered fullscreen>
      <Modal.Header>
        <CenteredLogo>
          <AppLogo height={"40vh"} />
        </CenteredLogo>
      </Modal.Header>
      <Modal.Body>
        <XYCenteredContent className="h-100">
          <ul>
            <li>
              <h5>
                This site is not compatible for iOS devices, also not for Safari
                browser for macOS.
              </h5>
            </li>
            <li>
              <h5>Please open this site in a non-iOS device.</h5>
            </li>
            <li>
              <h5>
                If you're using macOS, you can use a browser other than Safari.
              </h5>
            </li>
          </ul>
        </XYCenteredContent>
      </Modal.Body>
      <Modal.Footer>
        <h5 className="m-auto">We are sorry for the inconvenience.</h5>
      </Modal.Footer>
    </Modal>
  );
}
