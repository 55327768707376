export const QUERY = {
  find: "find",
  findOne: "findOne",
  create: "create",
  findOneAndUpdate: "update",
  findOneAndDelete: "delete",
  deleteAll: "deleteAll",
};

export const commonQuery = async (
  schemaConnection,
  query,
  data = {},
  key = null
) => {
  try {
    let res;
    const model = await schemaConnection();
    switch (query) {
      case QUERY.find:
        res = await model.getAll();
        break;
      case QUERY.findOne:
        res = await model.get(key);
        break;
      case QUERY.create:
        res = await model.add(data);
        break;
      case QUERY.findOneAndUpdate:
        res = await model.put(data);
        break;
      case QUERY.findOneAndDelete:
        res = await model.delete(key);
        res = 1;
        break;
      case QUERY.deleteAll:
        await model.clear();
        res = 1;
        break;
    }

    if (!res || !data) {
      return {
        status: 0,
        message: "Error, please try again.",
      };
    } else {
      return {
        status: 1,
        data: res,
      };
    }
  } catch (error) {
    return {
      status: 0,
      error: error,
    };
  }
};

// insert
// db.transaction("User", "readwrite").objectStore("User").add({ name: "raj", no: "1234567890" }).then(result => {
//       console.log(result);
// }).catch(error => {
//       console.log(error);
// })

// get
// db.transaction("User").objectStore("User").get(1).then(result => {
//       console.log(result);
// }).catch(error => {
//       console.log(error);
// })

// getAll
// db.transaction("User").objectStore("User").getAll().then(result => {
//       console.log(result);
// }).catch(error => {
//       console.log(error);
// })

// // delete
// db.transaction("User", 'readwrite').objectStore("User").delete(4).then(result => {
//       console.log(result);
// }).catch(error => {
//       console.log(error);
// })

// deleteAll
// db.transaction("User", 'readwrite').objectStore("User").clear().then(result => {
//       console.log(result);
// }).catch(error => {
//       console.log(error);
// })

// update
// db.transaction("User", "readwrite").objectStore("User").put({ "name": "Rock", "no": "111111111", id: 3 }).then(result => {
//       console.log(result);
// }).catch(error => {
//       console.log(error);
// })
