import styled from "styled-components";

export const UnreadCount = styled.span`
  margin-right: 4px;
  background-color: #2871d8;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 2px;
  height: 24px;
  width: 24px;
  color: #fff;
  font-size: 15px;
  border-radius: 50%;
`;

export const LastMessageTime = styled.h6`
  color: #546071;
`;

export const CardButtons = styled.h6`
  width: 48%;
`;

export const HighlightedText = styled.pre`
  font-size: 18px;
  display: inline;
  margin: 0 !important;
  font-family: "Lato";
  background-color: ${(props) =>
    props.highlight && "cornflowerblue"} !important;
  color: ${(props) => props.highlight && "white"} !important;
`;

export const UserNameContainer = styled.div.attrs({ className: "w-100" })`
  font-size: 16px !important;
  color: #6c757d !important;
`;

export const UserBox = styled.div.attrs({
  className: "mt-3 d-flex align-items-center gap-3",
})`
  border-bottom: 1px solid #d5e0e9;
  padding-bottom: 8px;
`;
