import React from "react";
import { CheckBox } from "../../../CommonThings/common.styled";

function MessageSelectionInput({
  chatDetail,
  selectedMessages,
  setSelectedMessages,
  data,
  me,
  i,
}) {
  const messageSelectionFn = (e, data) => {
    if (!chatDetail?.is_ad) {
      if (e.target.checked) {
        if (!selectedMessages.includes(data.id)) {
          setSelectedMessages((prev) => prev.concat(data.id));
        }
      } else {
        if (selectedMessages.includes(data.id)) {
          selectedMessages.splice(selectedMessages.indexOf(data.id), 1);
          setSelectedMessages([...selectedMessages]);
        }
      }
    }
  };
  return (
    <CheckBox
      hide={chatDetail?.is_ad}
      me={me}
      onChange={(e) => messageSelectionFn(e, data)}
      checked={!selectedMessages.includes(data.id) ? false : true}
      name={i}
      id={i}
    />
  );
}

export default MessageSelectionInput;
