import { Dropdown } from "react-bootstrap";
import { BiSelectMultiple } from "react-icons/bi";
import styled from "styled-components";
import { XYCenteredContent } from "../CommonThings/common.styled";
import { HiOutlineSearch } from "react-icons/hi";

export const SidebarMain = styled.div`
  width: 480px;
  max-width: 100%;
  height: 100vh;
  background-color: #fff;
  position: fixed;
  transition: 0.5s;
  user-select: none;

  button {
    background-color: transparent !important;
    box-shadow: none !important;
    padding: none !important;
    border: none;
    color: #214881 !important;
  }

  @media (max-width: 1024px) {
    margin-left: -480px;
    z-index: 3;
  }
`;

export const ListMapping = styled.div`
  overflow-y: auto;
  height: calc(100% - 26px);
  transition: height 0.5s;
  height: ${(props) => props.showAds && "calc(100% - 370px)"};
`;

export const ListContainer = styled.div`
  height: calc(100% - 80px);
  position: relative;
`;

export const SelectIcon = styled(BiSelectMultiple)`
  font-size: 1.4rem;
  color: #214881;
`;

export const SearchIcon = styled(HiOutlineSearch)`
  font-size: 1.2rem;
  color: #9eaabd;
  position: absolute;
  left: 28%;
  top: 2rem;
`;

export const MenuToggle = styled(Dropdown.Toggle)`
  ::after {
    display: none !important;
  }
`;

export const MenuItems = styled(Dropdown.Menu)`
  background-color: #ffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  border: none;
  width: 180px;
  height: auto;
  margin-top: 12px;
  transform: translate(-128px, 36px) !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;

  button {
    display: flex;
    align-items: center;
    color: #0d213d;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    gap: 15px;
    margin: 20px 0;
  }
`;

export const SidebarMenu = styled.div`
  position: relative;
`;

export const SidebarHeader = styled(XYCenteredContent)`
  background-color: #fff;
  box-shadow: 0px 3px 8px #d5e0e9;
  width: 100%;
  height: 80px;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;

  input {
    padding: 12px 16px;
    border: 1px solid #d5e0e9;
    border-radius: 12px;
    background: #ffffff;
    width: 236px;
    max-width: 100%;
    padding-left: 45px;
    height: 48px;
    outline: none;
    background-size: 23px;
    background-repeat: no-repeat;
    background-position: calc(100% - 200px) 12px, calc(100% - 20px) 14px, 100% 0;
  }

  input::-webkit-input-placeholder {
    color: #9eaabd;
  }

  @media (max-width: 425px) {
    padding: 0 !important;
  }

  @media (max-width: 375px) {
    input {
      width: 200px;
      padding-left: 50px;
      background-position: calc(100% - 160px) 12px, calc(100% - 20px) 14px,
        100% 0;
    }
  }
`;
