import React from "react";
import { XYCenteredContent } from "../../CommonThings/common.styled";
import { StyledLoader, StyledLoaderContent } from "./loader.styled";
import { Oval } from "react-loader-spinner";

function CMLoader({ loading_text }) {
  return (
    <XYCenteredContent justify="center" dir="column" className="p-3">
      <StyledLoader>
        <Oval
          height={80}
          width={80}
          color="#3b62eb"
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#3b61eb60"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
        <StyledLoaderContent>
          <img src="/chat-mail-logo192.png" width={"60px"} />
        </StyledLoaderContent>
      </StyledLoader>
      {loading_text && <p className="text-center">{loading_text}</p>}
    </XYCenteredContent>
  );
}

export default CMLoader;
