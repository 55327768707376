import styled from "styled-components";

export const MemberCard = styled.li.attrs({
  className: "d-flex align-items-center justify-content-between p-2 w-100",
})`
  cursor: pointer;
  padding-inline: 4% !important;
  :hover {
    background-color: #122e57;
    color: #fff;
  }
`;

export const MemberText = styled.h6`
  @media (max-width: 512px) {
    font-size: 14px;
  }
`;

export const MemberTextContainer = styled.div`
  margin-left: 0.5rem;
`;

export const MemberBadge = styled.span`
  font-size: 12px;
  border: 1px solid green;
  color: green;
  height: fit-content;
  padding: 2px 10px;
  border-radius: 50px;
  user-select: none;

  @media (max-width: 575px) {
    font-size: 10px;
  }
`;
