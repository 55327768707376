import styled from "styled-components";
import {
  XYCenteredContent,
  customScroll,
} from "../../CommonThings/common.styled";

export const SelectMembers = styled.li`
  position: relative;
  border-color: transparent;
  border-width: 0px;
  padding: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  span {
    font-size: 16px !important;
    color: #262a50 !important;
    margin-left: 8px;
  }

  :hover {
    background-color: #214881;
    span {
      color: #fff !important;
    }
  }

  :active {
    color: #fff;
    background-color: #214881;
  }
`;

export const SelectMembersContainer = styled(XYCenteredContent)`
  flex-wrap: wrap;
  background-color: #e5f8ff !important;
  border-radius: 5px;

  ${customScroll()}

  @media (max-width: 575px) {
    max-height: 200px !important;
    overflow: auto;
  }
`;

export const MembersList = styled.ul`
  background-color: white;
  padding: 0px;
  margin-inline: 8px;
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  align-items: stretch;
  max-width: 320px;
  max-height: 320px;
  overflow: auto;
  border-radius: 8px;
  left: -20px;
  right: 0;
  bottom: ${(props) =>
    props.parentHeight == "60" ? 40 : props.parentHeight - 20}px;
  box-shadow: 1px 2px 8px -6px black;
  padding-top: 4px;
  padding-bottom: 4px;
  border: 1px solid #d5e0e9;
  z-index: 4;

  ${customScroll()}
`;

export const SelectMembersInput = styled.input.attrs({ type: "text" })`
  margin-left: 4px;
  background-color: #e5f8ff;
  max-width: 100% !important;
  min-height: 32px !important;
  ${(props) =>
    `width: ${
      props.inputValue.length ? props.inputValue.length * 10 : 2
    }px !important`}
`;

export const SelectedMember = styled.span`
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #262a50 !important;
  margin: 0.5% !important;
  padding: 2px !important;
  padding-right: 12px !important;
  border-radius: 5px;
  background-color: #262a5030;
  position: relative;
  min-width: 80px;

  svg {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 12px;
    cursor: pointer;
  }
`;

export const SendToTxt = styled.span`
  font-size: 16px !important;
  color: white !important;
  background-color: #262a50;
  padding: 6px;
  padding-left: 9px;
  border-radius: 5px 0px 0px 5px;
  position: sticky;
  top: 0;
  z-index: 4;

  @media (max-width: 575px) {
    width: 100%;
    border-radius: 5px;
  }
`;
