import React, { createContext } from "react";
import { CgFileDocument } from "react-icons/cg";
import { IoIosMusicalNotes } from "react-icons/io";
import { MdOutlinePhotoSizeSelectActual } from "react-icons/md";
import { AiOutlineVideoCamera } from "react-icons/ai";
import ActionButtons from "./ActionButtons/ActionButtons";
import {
  fireStoreGetActivity,
  getTypingUsers,
} from "../../../../Auth/firebase/firebaseQueries";
import { useEffect } from "react";
import { useState } from "react";
import {
  getTimeFormat,
  getUpdatedProfileImage,
} from "../../../CommonThings/CommonThings";
import moment from "moment";
import {
  CheckBox,
  OnlineStatus,
  ProfileImage,
  ProfileImageContainer,
  UserLastMessage,
  UserName,
  UserTyping,
  XYCenteredContent,
} from "../../../CommonThings/common.styled";
import {
  CardButtons,
  HighlightedText,
  LastMessageTime,
  UnreadCount,
  UserBox,
} from "./userCard.styled";

export const UserCardContext = createContext();

const UserCard = ({
  onUserListClick,
  setSelectedChats,
  i,
  selectedChats,
  user,
  fetchAllChats,
  setChatDetail,
  chatDetail,
  select,
  clearConversation,
  callLogoutAPI,
  setShowOTPModel,
  searchResult,
  highlight,
  search,
}) => {
  const [senderActivity, setSenderActivity] = useState({});
  const [isOnline, setIsOnline] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [inviteTime, setInviteTime] = useState("");
  const [groupTyping, setGroupTyping] = useState({
    groupID: "",
    typing_str: "",
  });
  const [profileImage, setProfileImage] = useState(
    user?.userData?.Profilepic ||
      user?.userData?.profilePic ||
      user?.groupData?.profilePic ||
      user?.groupData?.Profilepic ||
      (user?.userData && "/images/user.ico") ||
      (user?.groupData && "./images/g_icon.ico")
  );

  useEffect(() => {
    getUpdatedProfileImage(user, setProfileImage, false);
  }, []);

  useEffect(() => {
    showInvitationTime();
    getUpdatedProfileImage(user, setProfileImage, true);
    if (user?.userData) {
      fireStoreGetActivity(user?.userData?.SenderID, setSenderActivity);
    } else if (user?.groupData) {
      user?.groupData?.members.forEach((m) => {
        fireStoreGetActivity(m?.ID, setSenderActivity);
      });
    }
  }, [user]);

  const showInvitationTime = () => {
    if (
      !user.is_verified &&
      (user?.userData?.invite_time ||
        user?.groupData?.invitedAt ||
        user?.lastMessage?.currentTime)
    ) {
      const invite_time = new Date(
        user?.userData?.invite_time ||
          user?.groupData?.invitedAt ||
          user?.lastMessage?.currentTime
      );
      var YESTERDAY = moment().clone().subtract(1, "days").startOf("day");
      let isYesterday = moment(invite_time).isSame(YESTERDAY, "d");

      let daysAgo = moment().diff(moment(invite_time), "days");

      const local_time = invite_time.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });

      if (daysAgo > 1) {
        return setInviteTime(`${daysAgo} days ago`);
      } else if (isYesterday) {
        return setInviteTime("Yesterday");
      } else {
        return setInviteTime(local_time);
      }
    } else {
      return setInviteTime("");
    }
  };

  const selectOnChangeHandler = (e) => {
    const chatID = user.userData
      ? user.userData.SenderID
      : user.groupData && user.groupData.groupID;

    if (!user.userData?.is_ad) {
      if (e.target.checked) {
        if (!selectedChats.includes(chatID)) {
          setSelectedChats((prev) => prev.concat(chatID));
        }
      } else {
        if (selectedChats.includes(chatID)) {
          selectedChats.splice(selectedChats.indexOf(chatID), 1);
          setSelectedChats([...selectedChats]);
        }
      }
    }
  };
  useEffect(() => {
    if (user?.groupData?.groupID) {
      setIsOnline(false);
      senderActivity?.receptionid == user?.groupData?.groupID &&
        getAllTypingMembers();
    }
    if (senderActivity?.uid == user?.userData?.SenderID) {
      setIsOnline(senderActivity?.islive === "active");
      setIsTyping(
        senderActivity?.receptionid == user?.userData?.contactID &&
          senderActivity?.istyping
      );
    }
  }, [senderActivity]);

  const getAllTypingMembers = async () => {
    const typingMembers = await getTypingUsers(user?.groupData?.groupID);
    let final_arr = typingMembers.filter(
      (doc) => doc.uid != user?.groupData?.contactID
    );
    final_arr = final_arr.map(
      (doc) => user?.groupData?.members?.find((m) => m?.ID == doc.uid)?.name
    );
    if (!final_arr?.length) {
      setGroupTyping({
        groupID: user?.groupData?.groupID,
        typing_str: "",
      });
    } else if (final_arr?.length == 1) {
      setGroupTyping({
        groupID: user?.groupData?.groupID,
        typing_str: `${final_arr?.join()} is typing . . .`,
      });
    } else if (final_arr?.length == 2) {
      setGroupTyping({
        groupID: user?.groupData?.groupID,
        typing_str: `${final_arr?.join(" and ")} are typing . . .`,
      });
    } else if (final_arr?.length > 2) {
      setGroupTyping({
        groupID: user?.groupData?.groupID,
        typing_str: `${final_arr?.length} people are typing . . .`,
      });
    }
  };

  const highlightTextChars = (textToBeHighlighted) => {
    let textArray = textToBeHighlighted?.split("");
    return textArray.map((char, i) => {
      const checkStr =
        char?.trim() &&
        search?.toLowerCase()?.includes(char?.trim()?.toLowerCase());

      return (
        <HighlightedText key={i} highlight={checkStr}>
          {char}
        </HighlightedText>
      );
    });
  };

  return (
    <UserBox
      onClick={() => {
        onUserListClick(user);
      }}
    >
      {select && (
        <CheckBox
          checked={selectedChats.includes(
            user?.userData?.SenderID || user?.groupData?.groupID
          )}
          onChange={selectOnChangeHandler}
          name={i}
          id={i}
        />
      )}
      <ProfileImageContainer>
        <OnlineStatus isOnline={isOnline} />
        <ProfileImage
          src={profileImage}
          onError={() =>
            setProfileImage(
              user?.userData
                ? "/images/user.ico"
                : user?.groupData && "./images/g_icon.ico"
            )
          }
          width="60px"
          height="60px"
          alt=""
        />
      </ProfileImageContainer>
      <div className="w-100">
        <UserName className={"d-flex"}>
          {search && highlight === "name"
            ? highlightTextChars(
                user.userData?.SenderName || user.groupData?.groupName
              )
            : user.userData?.SenderName || user.groupData?.groupName}
        </UserName>
        <XYCenteredContent justify="space-between">
          <XYCenteredContent className="gap-3">
            {user.is_verified ? (
              isTyping || groupTyping.typing_str ? (
                user?.userData ? (
                  isTyping && <UserTyping>typing . . .</UserTyping>
                ) : (
                  groupTyping.groupID == user?.groupData?.groupID && (
                    <UserTyping>{groupTyping.typing_str}</UserTyping>
                  )
                )
              ) : (
                <>
                  {!search &&
                    (Number(user?.lastMessage?.api_response?.typeID) === 2 ? (
                      <>
                        <IoIosMusicalNotes />
                        <UserLastMessage>{"Voice"}</UserLastMessage>
                      </>
                    ) : Number(user?.lastMessage?.api_response?.typeID) ===
                      3 ? (
                      <>
                        <AiOutlineVideoCamera />
                        <UserLastMessage>
                          {!user?.lastMessage?.api_response?.body && "Video"}
                        </UserLastMessage>
                      </>
                    ) : Number(user?.lastMessage?.api_response?.typeID) ===
                      4 ? (
                      <>
                        <MdOutlinePhotoSizeSelectActual />
                        <UserLastMessage>
                          {!user?.lastMessage?.api_response?.body && "Image"}
                        </UserLastMessage>
                      </>
                    ) : (
                      Number(user?.lastMessage?.api_response?.typeID) === 5 && (
                        <>
                          <CgFileDocument />
                          <UserLastMessage width={"196px"}>
                            {!user?.lastMessage?.api_response?.body &&
                              user?.lastMessage?.api_response?.filePath
                                ?.split("/")
                                ?.slice(-1)[0]}
                          </UserLastMessage>
                        </>
                      )
                    ))}
                  <UserLastMessage width={"180px"}>
                    {search && highlight === "message" ? (
                      <span className="d-flex">
                        {Number(user?.foundMessage?.api_response?.typeID) ===
                          5 && <CgFileDocument size={24} />}
                        <UserLastMessage>
                          {highlightTextChars(
                            Number(user?.foundMessage?.api_response?.typeID) ===
                              5
                              ? user?.foundMessage?.api_response?.filePath
                                  ?.split("/")
                                  ?.slice(-1)[0]
                              : user?.foundMessage?.api_response?.body
                          )}
                        </UserLastMessage>
                      </span>
                    ) : (
                      user?.lastMessage?.api_response?.body
                    )}
                  </UserLastMessage>
                </>
              )
            ) : (
              <UserLastMessage style={{ color: "#1c66ce" }}>
                Invitation
              </UserLastMessage>
            )}
          </XYCenteredContent>
        </XYCenteredContent>
      </div>
      <CardButtons>
        <XYCenteredContent justify="flex-end">
          {user.is_verified &&
            (user?.userData?.SenderID != chatDetail?.userData?.SenderID ||
              user?.groupData?.groupID != chatDetail?.groupData?.groupID) &&
            user?.unReadCount > 0 && (
              <UnreadCount>{user?.unReadCount}</UnreadCount>
            )}

          <LastMessageTime>
            {getTimeFormat(
              user.is_verified
                ? search && highlight === "message"
                  ? user?.foundMessage?.api_response?.sentTime ||
                    user?.foundMessage?.currentTime
                  : user?.lastMessage?.api_response?.sentTime ||
                    user?.lastMessage?.currentTime
                : user?.groupData?.invitedAt,
              false
            ) || inviteTime}
          </LastMessageTime>
        </XYCenteredContent>
        {!user.is_ad && (
          <UserCardContext.Provider value={{ search }}>
            <ActionButtons
              setChatDetail={setChatDetail}
              user={user}
              fetchAllChats={fetchAllChats}
              clearConversation={clearConversation}
              callLogoutAPI={callLogoutAPI}
              setShowOTPModel={setShowOTPModel}
              setProfileImage={setProfileImage}
            />
          </UserCardContext.Provider>
        )}
      </CardButtons>
    </UserBox>
  );
};

export default UserCard;
