import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { BiChevronRight } from "react-icons/bi";
import { MdExitToApp, MdGroups, MdOutlineDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { deleteChatFromChatList } from "../../../../../IndexedDB/queries";
import { XYCenteredContent } from "../../../../CommonThings/common.styled";
import GroupInfoModal from "../GroupInfoModal/GroupInfoModal";
import {
  ActionButton,
  Reject,
  DeleteTitle,
  Confirm,
} from "./actionButtons.styled";

function ActionButtons({
  user,
  fetchAllChats,
  clearConversation,
  setChatDetail,
  callLogoutAPI,
  setShowOTPModel,
  setProfileImage,
}) {
  const [show, setShow] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const handleClose = async () => {
    await fetchAllChats();
    setShow(false);
    const users_in_chat = await fetchAllChats();
  };
  const handleShow = () => {
    setShow(true);
    setChatDetail(user);
  };
  const handleCloseInfo = () => setShowInfo(false);
  const handleShowInfo = () => setShowInfo(true);

  const deleteChatFromList = async (chat_data) => {
    const deleted = await deleteChatFromChatList(chat_data);
    if (deleted && deleted.status == 1) {
      toast.success(deleted.message);
      setChatDetail(null);
      const users_in_chat = await fetchAllChats();
      if (!users_in_chat || !users_in_chat.length) {
        await callLogoutAPI(null, "complete");
      }
    } else {
      toast.error(deleted?.message ?? "Something went wrong !!");
    }
  };

  useEffect(() => {
    setShowOTPModel(false);
  }, [show, showInfo]);

  return (
    <>
      <XYCenteredContent justify="flex-end">
        <BiChevronRight size={"20px"} />
        {user.groupData ? (
          <>
            <ActionButton onClick={() => handleShowInfo(true)}>
              <MdGroups />
            </ActionButton>
            <ActionButton color="red">
              <MdExitToApp onClick={() => handleShow()} />
            </ActionButton>
          </>
        ) : (
          <>
            <ActionButton>
              <img src="./images/ic-chat.ico" width="24" alt="" />
            </ActionButton>
            <ActionButton color="red" onClick={() => handleShow()}>
              <MdOutlineDelete />
            </ActionButton>
          </>
        )}
      </XYCenteredContent>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="m-auto border-0">
          <Modal.Title>
            <DeleteTitle>
              {user?.userData?.SenderName || user?.groupData?.groupName}
            </DeleteTitle>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="text-center">
            {user.groupData
              ? "Do you really want to exit this group ?"
              : "Do you really want to delete this chat ?"}
          </h5>
        </Modal.Body>
        <Modal.Footer className="m-auto border-0">
          <Reject onClick={handleClose}>No</Reject>
          <Confirm
            variant="danger"
            onClick={async () => {
              await clearConversation();
              setShowOTPModel(false);
              setTimeout(async () => {
                await deleteChatFromList(user);
                handleClose();
              }, 200);
            }}
          >
            Yes
          </Confirm>
        </Modal.Footer>
      </Modal>

      {user?.groupData && (
        <GroupInfoModal
          showGroupInfo={showInfo}
          handleClose={handleCloseInfo}
          group={user}
          setProfileImage={setProfileImage}
        />
      )}
    </>
  );
}

export default ActionButtons;
