import styled from "styled-components";

export const StyledLoader = styled.div`
  position: relative;
`;

export const StyledLoaderContent = styled.div`
  position: absolute;
  top: 16%;
  left: 15%;
`;
