import { leaveGroupChatAPI, logoutFromServer } from "../Auth/api";
import { COMMON_ERROR } from "../Auth/config";
import {
  ChatSchema,
  UserSchema,
  UserMappingSchema,
  GroupSchema,
  GroupChatSchema,
} from "./DBSchema";
import { commonQuery, QUERY } from "./queryHelper";
const { find, findOne, create, findOneAndUpdate, findOneAndDelete, deleteAll } =
  QUERY;

//USERS
export const getAllUsers = async () => {
  const allUserResponse = await commonQuery(UserSchema, find);
  if (allUserResponse.status === 1) {
    return {
      status: 1,
      message: "All users fetched successfully.",
      data: allUserResponse.data,
    };
  } else {
    return { status: 0, message: COMMON_ERROR };
  }
};

export const getUser = async (key) => {
  const userResponse = await commonQuery(UserSchema, findOne, {}, key);
  if (userResponse.status === 1) {
    return {
      status: 1,
      message: "User fetched successfully.",
      data: userResponse.data,
    };
  } else {
    return { status: 0, message: COMMON_ERROR };
  }
};

export const createUser = async (data) => {
  let foundUser = null;
  const allUsers = await getAllUsers();
  if (allUsers.status === 1) {
    const existingUser = allUsers.data.find(
      (user) =>
        user?.userData?.SenderID === data?.userData?.SenderID &&
        user?.userData?.contactID === data?.userData?.contactID
    );
    if (existingUser) {
      foundUser = {
        ...existingUser,
        userData: {
          ...existingUser?.userData,
          SenderName: data?.userData?.SenderName,
          Profilepic: data?.userData?.Profilepic,
          webID: data.userData.webID,
        },
        lastMessage: data?.lastMessage?.api_response?.uniqueMessageID
          ? data?.lastMessage
          : existingUser.lastMessage,
        is_verified: data?.is_verified,
      };
    }
  }
  const userResponse = foundUser
    ? await updateUser(foundUser)
    : await commonQuery(UserSchema, create, data);
  if (userResponse.status === 1) {
    return {
      status: 1,
      message: "User created successfully.",
      data: userResponse.data,
    };
  } else {
    return { status: 0, message: COMMON_ERROR };
  }
};

export const updateUser = async (data) => {
  const updateUserResponse = await commonQuery(
    UserSchema,
    findOneAndUpdate,
    data
  );
  if (updateUserResponse.status === 1) {
    return {
      status: 1,
      message: "User updated successfully.",
      data: updateUserResponse.data,
    };
  } else {
    return { status: 0, message: COMMON_ERROR };
  }
};

//GROUPS
export const getAllGroups = async () => {
  const allGroupResponse = await commonQuery(GroupSchema, find);
  if (allGroupResponse.status === 1) {
    return {
      status: 1,
      message: "All Groups fetched successfully.",
      data: allGroupResponse.data,
    };
  } else {
    return { status: 0, message: COMMON_ERROR };
  }
};

export const getGroup = async (key) => {
  const get_group = await commonQuery(GroupSchema, findOne, {}, key);
  if (get_group.status === 1) {
    return {
      status: 1,
      message: "Group fetched successfully.",
      data: get_group.data,
    };
  } else {
    return { status: 0, message: COMMON_ERROR };
  }
};

export const createGroup = async (new_group) => {
  let foundGroup = null;
  const allGroups = await getAllGroups();
  if (allGroups.status === 1) {
    const existingGroup = allGroups.data.find(
      (group) => group?.groupData?.groupID === new_group.groupData.groupID
    );
    if (existingGroup) {
      foundGroup = {
        ...existingGroup,
        groupData: new_group.groupData,
        is_verified: false,
        lastMessage: existingGroup.lastMessage,
      };
    }
  }
  const groupResponse = foundGroup
    ? await updateGroup(foundGroup)
    : await commonQuery(GroupSchema, create, new_group);
  if (groupResponse.status === 1) {
    return {
      status: 1,
      message: "Group created successfully.",
      data: groupResponse.data,
    };
  } else {
    return { status: 0, message: COMMON_ERROR };
  }
};

export const updateGroup = async (data) => {
  const updateGroupResponse = await commonQuery(
    GroupSchema,
    findOneAndUpdate,
    data
  );
  if (updateGroupResponse.status === 1) {
    return {
      status: 1,
      message: "Group updated successfully.",
      data: updateGroupResponse.data,
    };
  } else {
    return { status: 0, message: COMMON_ERROR };
  }
};

export const deleteGroupInChat = async (data) => {
  try {
    //remove group from list
    const deleteGroupResponse = await commonQuery(
      GroupSchema,
      findOneAndDelete,
      {},
      data.id
    );

    if (deleteGroupResponse.status === 1) {
      if (data?.groupData) {
        await leaveGroupChatAPI(
          Number(data.groupData.contactID),
          Number(data.groupData.groupID)
        );
      }
      return {
        status: 1,
        message: "Left the group chat successfully.",
        data: deleteGroupResponse.data,
      };
    } else {
      return { status: 0, message: COMMON_ERROR };
    }
  } catch (error) {
    console.error("deleteUserInChatError", error);
  }
};

export const deleteChatFromChatList = async (data) => {
  try {
    //update mapping
    if (data.userData) {
      const AllUsersRes = await getAllUserMapping();
      const all_users = AllUsersRes.data;
      let update_user_mapping = await all_users.find(
        (user) => user.contact_id === data.userData.contactID
      );
      const updated_sender_ids = await update_user_mapping.sender_ids.filter(
        (id) => id !== data.id
      );
      update_user_mapping.sender_ids = updated_sender_ids;
      let updated_mapping = await updateUserMapping(update_user_mapping);
      //

      //remove user from list
      const deleteUserResponse = await commonQuery(
        UserSchema,
        findOneAndDelete,
        {},
        data.id
      );

      if (deleteUserResponse.status === 1) {
        const sessionExpiry = await logoutFromServer({
          webID: data?.userData?.webID,
        });
        if (sessionExpiry.status == 200) {
          return {
            status: 1,
            message: "User deleted successfully.",
            data: deleteUserResponse.data,
          };
        } else {
          return { status: 0, message: COMMON_ERROR };
        }
      } else {
        console.error("deleteUserResponseERROR");
      }
    } else {
      const delete_group = await deleteGroupInChat(data);
      return delete_group;
    }
  } catch (error) {
    console.error("deleteChatFromChatList", error);
  }
};

//USER CHATS
export const getAllChats = async () => {
  const allChatResponse = await commonQuery(ChatSchema, find);
  if (allChatResponse.status === 1) {
    return {
      status: 1,
      message: "All chats fetched successfully.",
      data: allChatResponse.data,
    };
  } else {
    return { status: 0, message: COMMON_ERROR };
  }
};

export const getChat = async (key) => {
  const ChatResponse = await commonQuery(ChatSchema, findOne, {}, key);
  if (ChatResponse.status === 1) {
    return {
      status: 1,
      message: "Chat fetched successfully.",
      data: ChatResponse.data,
    };
  } else {
    return { status: 0, message: COMMON_ERROR };
  }
};

export const createChat = async (data) => {
  const createChatResponse = await commonQuery(ChatSchema, create, data);
  if (createChatResponse.status === 1) {
    return {
      status: 1,
      message: "Chat created successfully.",
      data: createChatResponse.data,
    };
  } else {
    return { status: 0, message: COMMON_ERROR };
  }
};

export const updateChat = async (data) => {
  const updateChatResponse = await commonQuery(
    ChatSchema,
    findOneAndUpdate,
    data
  );
  if (updateChatResponse.status === 1) {
    return {
      status: 1,
      message: "Chat updated successfully.",
      data: updateChatResponse.data,
    };
  } else {
    return { status: 0, message: COMMON_ERROR };
  }
};

//GROUP CHATS
export const getAllGroupChats = async () => {
  const allGroupChatResponse = await commonQuery(GroupChatSchema, find);
  if (allGroupChatResponse.status === 1) {
    return {
      status: 1,
      message: "All chats fetched successfully.",
      data: allGroupChatResponse.data,
    };
  } else {
    return { status: 0, message: COMMON_ERROR };
  }
};

export const getGroupChat = async (key) => {
  const groupChatResponse = await commonQuery(
    GroupChatSchema,
    findOne,
    {},
    key
  );
  if (groupChatResponse.status === 1) {
    return {
      status: 1,
      message: "Chat fetched successfully.",
      data: groupChatResponse.data,
    };
  } else {
    return { status: 0, message: COMMON_ERROR };
  }
};

export const createGroupChat = async (data) => {
  const createChatResponse = await commonQuery(GroupChatSchema, create, data);
  if (createChatResponse.status === 1) {
    return {
      status: 1,
      message: "Chat created successfully.",
      data: createChatResponse.data,
    };
  } else {
    return { status: 0, message: COMMON_ERROR };
  }
};

export const deleteGroupChat = async (id) => {
  const deleteChatResponse = await commonQuery(
    GroupChatSchema,
    findOneAndDelete,
    {},
    id
  );
  console.warn("deleteChatResponse", deleteChatResponse, id);
  if (deleteChatResponse.status === 1) {
    return {
      status: 1,
      message: "Group Chat deleted successfully.",
      data: deleteChatResponse.data,
    };
  } else {
    return { status: 0, message: COMMON_ERROR };
  }
};

// createGroupChat({ message: "Hello", sentTime: new Date(), sentBy: "John" });

export const updateGroupChat = async (data) => {
  const updateGroupChatResponse = await commonQuery(
    GroupChatSchema,
    findOneAndUpdate,
    data
  );
  if (updateGroupChatResponse.status === 1) {
    return {
      status: 1,
      message: "Chat updated successfully.",
      data: updateGroupChatResponse.data,
    };
  } else {
    return { status: 0, message: COMMON_ERROR };
  }
};

export const deleteChatMessage = async (data) => {
  try {
    const { msg_id, chatDetail } = data;
    const current_senderID = chatDetail?.id;

    const deleteMessageResponse = await commonQuery(
      ChatSchema,
      findOneAndDelete,
      {},
      msg_id
    );

    if (deleteMessageResponse.status === 1) {
      const all_chat_messages = await getAllChats();
      if (all_chat_messages.status === 1) {
        //update user's last message in list
        if (chatDetail.userData) {
          const { SenderID } = chatDetail.userData;
          const current_chat_messages = all_chat_messages.data.filter(
            (el) => el.api_response.recipientUserID == SenderID
          );
          const last_msg_idx = current_chat_messages.length - 1;
          const last_msg = current_chat_messages[last_msg_idx];
          const update_sender_last_message = await updateUser({
            ...chatDetail,
            lastMessage: last_msg,
            id: current_senderID,
          });
          if (update_sender_last_message.status === 1) {
          } else {
            console.error(update_sender_last_message);
          }
        } else if (chatDetail.groupData) {
          const allGroupChatData = await getAllGroupChats();

          const current_group_chat = allGroupChatData.data.find(
            (el) => el.group_id == chatDetail.groupData.groupID
          );
          const updated_chat_ids = current_group_chat?.chat_ids?.filter(
            (el) => el !== msg_id
          );

          if (updated_chat_ids?.length) {
            const update_group_chat = await updateGroupChat({
              ...current_group_chat,
              chat_ids: updated_chat_ids,
            });

            if (update_group_chat?.status == 1) {
              const last_chat_id = Math.max(...updated_chat_ids);
              const current_group = await getGroup(chatDetail.id);
              const get_last_message = await getChat(last_chat_id);
              const update_group = await updateGroup({
                ...current_group.data,
                lastMessage: get_last_message.data,
              });
            }
          }
        }
        return {
          status: 1,
          message: "Message deleted successfully !!",
          data: msg_id,
        };
      }
    } else {
      return { status: 0, message: COMMON_ERROR };
    }
  } catch (error) {
    console.error("deleteChatMessageERROR", error);
    return { status: 0, message: "something went wrong", error_desc: error };
  }
};

export const getAllUserMapping = async () => {
  const allUserMappingResponse = await commonQuery(UserMappingSchema, find);
  if (allUserMappingResponse.status === 1) {
    return {
      status: 1,
      message: "All UserMapping fetched successfully.",
      data: allUserMappingResponse.data,
    };
  } else {
    return { status: 0, message: COMMON_ERROR };
  }
};

export const createUserMapping = async (data) => {
  let foundUser = null;
  const allUsers = await getAllUserMapping();
  if (allUsers.status === 1) {
    foundUser = allUsers.data.find(
      (user) => user.contact_id === data.contact_id
    );
    if (foundUser) {
      foundUser = {
        ...foundUser,
        is_login: true,
        sender_ids: foundUser.sender_ids.includes(data.sender_ids)
          ? foundUser.sender_ids
          : [...foundUser.sender_ids, data.sender_ids],
      };
    } else {
      data.sender_ids = [data.sender_ids];
    }
  }
  const userResponse = foundUser
    ? await updateUserMapping(foundUser)
    : await commonQuery(UserMappingSchema, create, data);
  if (userResponse.status === 1) {
    return {
      status: 1,
      message: "UserMapping created successfully.",
      data: userResponse.data,
    };
  } else {
    return { status: 0, message: COMMON_ERROR };
  }
};

export const updateUserMapping = async (data) => {
  const updateUserMappingResponse = await commonQuery(
    UserMappingSchema,
    findOneAndUpdate,
    data
  );
  if (updateUserMappingResponse.status === 1) {
    return {
      status: 1,
      message: "UserMapping updated successfully.",
      data: updateUserMappingResponse.data,
    };
  } else {
    return { status: 0, message: COMMON_ERROR };
  }
};

export const logout = async (data) => {
  const findAll = await getAllUserMapping();
  if (findAll.status === 1) {
    if (data?.contact_id) {
      const { contact_id } = data;
      const foundUser = findAll.data.find(
        (user) => user.contact_id === contact_id
      );
      foundUser.is_login = false;
      const user_login_status = await updateUserMapping(foundUser);
      return user_login_status;
    } else {
      return { status: 1, message: "UserMapping updated successfully." };
    }
  }
};
