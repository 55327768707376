import React, { useEffect, useState } from "react";

import { WEB_ACCESS_TYPE } from "../../../Auth/config";
import { getStaticpage } from "../../../Auth/api";
import { clearSiteDataFn } from "../../CommonThings/CommonThings";
import {
  AppleStore,
  AppStoreButtons,
  BodyBG,
  Line,
  LowerDiv,
  ORTextContainer,
  PlayStore,
  TextDownload,
  TextReadMessage,
  TextUseWeb,
  UseWebVersionButton,
  WelcomeHeading,
  WelcomeMain,
  WelcomePara,
} from "./start.styled";
import OTPComponent from "../../chat/otpComponent/OTPComponent";
import { AppLogo } from "../../CommonThings/common.styled";

const Start = ({ getUserData }) => {
  const [show, setShow] = useState(false);

  const [pageText, setPageText] = useState("");

  const getWelcomePageData = async () => {
    const pageDetail = await getStaticpage("Web Home Page");
    setPageText(pageDetail?.pageContent);
  };

  useEffect(() => {
    getWelcomePageData();
    clearSiteDataFn();
  }, []);

  return (
    <BodyBG>
      <WelcomeMain>
        <AppLogo />
        <WelcomeHeading>Welcome to {WEB_ACCESS_TYPE}! </WelcomeHeading>
        <WelcomePara dangerouslySetInnerHTML={{ __html: pageText }} />
        <LowerDiv>
          <TextReadMessage>To read the message:</TextReadMessage>
          <TextDownload>Download the app</TextDownload>
          <AppStoreButtons>
            <AppleStore />
            <PlayStore />
          </AppStoreButtons>

          <ORTextContainer>
            <Line />
            Or
            <Line />
          </ORTextContainer>
          <TextUseWeb>Use {WEB_ACCESS_TYPE} WEB</TextUseWeb>
          <UseWebVersionButton onClick={() => setShow(true)}>
            Use web version
          </UseWebVersionButton>
        </LowerDiv>
      </WelcomeMain>

      <OTPComponent show={show} setShow={setShow} getUserData={getUserData} />
    </BodyBG>
  );
};

export default Start;
